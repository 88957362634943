<template>
  <v-app v-scroll="onScroll">
    <v-main>
      <call-to-action></call-to-action>
      <front-navbar
          class="hidden-sm-and-down"
          :offset-top="offsetTop"
      ></front-navbar>
      <front-mobile-navbar class="hidden-md-and-up"></front-mobile-navbar>
      <v-container fluid>
        <div class="app-container">
          <div class="app-sidebar hidden-sm-and-down">
            <component
                :is="getSiteWidgetComponent(widget.type)"
                v-for="widget in leftWidgets"
                :key="widget.id"
                :text="widget.text"
                :data="widget"
                :url="widget.url"
                :poll-data="widget.poll"
                with-edit
            ></component>
          </div>

          <div class="app-content">
            <nuxt/>
          </div>
          <div class="app-sidebar hidden-sm-and-down">
            <div class="hidden-md-and-up">
              <component
                  :is="getSiteWidgetComponent(widget.type)"
                  v-for="widget in leftWidgets"
                  :key="widget.id"
                  :text="widget.text"
                  :data="widget"
                  :url="widget.url"
                  :poll-data="widget.poll"
                  with-edit
              ></component>
            </div>
            <component
                :is="getSiteWidgetComponent(widget.type)"
                v-for="widget in rightWidgets"
                :key="widget.id"
                :text="widget.text"
                :data="widget"
                :url="widget.url"
                :poll-data="widget.poll"
                with-edit
            ></component>
          </div>
        </div>
      </v-container>

      <AdvancesControls></AdvancesControls>

      <TheToTopButton :offset-top="offsetTop"></TheToTopButton>

      <cookie-policy key="1"></cookie-policy>

      <Footer></Footer>
      <v-btn
          v-for="(widget, i) in rightButtonsWidgets"
          :key="widget.id"
          dark
          width="250px"
          max-width="250px"
          :href="widget.url"
          :style="getRightSideBtnWidgetStyle(i)"
          x-large
          fixed
          target="_blank"
          :color="widget.button.color"
      >
        <v-icon class="mr-1">mdi-{{ widget.button.icon }}
        </v-icon
        >
        {{ widget.button.text }}
      </v-btn
      >
      <v-btn
          v-for="(widget, i) in leftButtonsWidgets"
          :key="widget.id"
          dark
          width="250px"
          max-width="250px"
          :href="widget.url"
          :style="getLeftSideBtnWidgetStyle(i, true)"
          x-large
          fixed
          target="_blank"
          :color="widget.button.color"
      >
        <v-icon class="mr-1">mdi-{{ widget.button.icon }}
        </v-icon
        >
        {{ widget.button.text }}
      </v-btn
      >
    </v-main>
    <client-only>
      <div id="fb-root"></div>
      <portal-target name="destination"></portal-target>
      <div id="google_translate_element2"></div>
    </client-only>
  </v-app>
</template>

<script>

import {mapActions, mapState} from 'vuex'
import CallToAction from '~/components/common/CallToAction'
import Footer from '~/components/common/Footer'
import AdvancesControls from '~/components/common/AdvancesControls'
import TheToTopButton from '~/components/common/TheToTopButton'
import FrontMobileNavbar from '~/components/navigation/FrontMobileNavbar'
import FrontNavbar from '~/components/navigation/FrontNavbar'
import CookiePolicy from '~/components/common/Cookie Policy'
import CitizenReportsWidget from '~/components/widget/CitizenReportsWidget'
import FacebookWidget from '~/components/widget/FacebookWidget'
import GalleryWidget from '~/components/widget/GalleryWidget'
import ImageWidget from '~/components/widget/ImageWidget'
import MenuWidget from '~/components/widget/MenuWidget'
import NewsletterWidget from '~/components/widget/NewsletterWidget'
import PetitionWidget from '~/components/widget/PetitionWidget'
import PollWidget from '~/components/widget/PollWidget'
import TextWidget from '~/components/widget/TextWidget'

export default {
  name: 'ClientLayout',
  auth: false,
  components: {
    CookiePolicy,
    FrontMobileNavbar,
    CallToAction,
    FrontNavbar,
    AdvancesControls,
    TheToTopButton,
    Footer,
    NewsletterWidget,
    TextWidget,
    PetitionWidget,
    CitizenReportsWidget,
    MenuWidget,
    ImageWidget,
    GalleryWidget,
    PollWidget,
    FacebookWidget,
  },
  data() {
    return {
      offsetTop: 0,
      adminCode: [],
    }
  },
  fetch() {
    return this.getData()
  },
  computed: {
    ...mapState('app', ['siteKey']),
    ...mapState('homeWidgets', [
      'leftWidgets',
      'rightWidgets',
      'pageWidgets',
      'buttonWidgets',
    ]),
    rightButtonsWidgets() {
      return this.buttonWidgets.filter((btn) => btn.location === 'right').reverse()
    },
    leftButtonsWidgets() {
      return this.buttonWidgets.filter((btn) => btn.location === 'left')
    },
  },
  mounted() {
    // eslint-disable-next-line nuxt/no-env-in-hooks
    if (window) {
      this.$nextTick(() => {
        this.googleTranslateInit()
      })
      window.addEventListener('keyup', (e) => {
        if (this.adminCode.length >= 5) {
          this.adminCode.shift()
        }

        this.adminCode.push(e.key)

        if (this.adminCode.join('') === 'admin') {
          this.$router.push(this.localePath('/categories'))
        }
      })

      window.interdeal = {
        'sitekey': this.siteKey,
        'Position': 'Left',
        'Menulang': 'RO',
        'domains': {
          'js': 'https://cdn.equalweb.com/',
          'acc': 'https://access.equalweb.com/',
        },
        'btnStyle': {
          'vPosition': [
            '80%',
            null,
          ],
          'scale': [
            '0.6',
            '0.6',
          ],
          'color': {
            'main': '',
            'second': '#ef5714',
          },
          'icon': {
            'type': 10,
            'shape': 'semicircle',
            'outline': true,
          },
        },
      }

      window.dataLayer = window.dataLayer || []
      const id = this.$store.state.app.analyticsId

      function gtag() {window.dataLayer.push(arguments)}

      gtag('js', new Date())
      gtag('config', id)

      setTimeout(() => {
        (function(doc, head, body) {
          const coreCall = doc.createElement('script')
          coreCall.src = 'https://cdn.equalweb.com/core/4.0.4/accessibility.js'
          coreCall.defer = true
          coreCall.integrity = 'sha512-LDvqiv8qYdF1MIqxiGZrvcDsmN6cZy0u0l23Dj7TVXmkVSNyzjtkcll8uCb8EGdwDVHjvisVYsAWuwTf6Mpu8g=='
          coreCall.crossOrigin = 'anonymous'
          coreCall.setAttribute('data-cfasync', true)

          const analyticsScript = doc.createElement('script')
          analyticsScript.src = `https://www.googletagmanager.com/gtag/js?id=${id}`
          analyticsScript.async = true

          body ? body.appendChild(coreCall) : head.appendChild(coreCall)
          body ? body.appendChild(analyticsScript) : head.appendChild(analyticsScript)
        })(document, document.head, document.body)
      }, 2000)
    }
  },
  methods: {
    ...mapActions('homeWidgets', ['getData']),
    googleTranslateInit() {
      const checkIfGoogleLoaded = setInterval(() => {

        if (window.google.translate.TranslateElement != null) {
          clearInterval(checkIfGoogleLoaded)
          this.googleTranslateElement('google_translate_element')
        }

      }, 100)

    },

    googleTranslateElement(id) {
      // eslint-disable-next-line no-new
      // const google = new window.google.translate.TranslateElement({
      //   pageLanguage: 'ro',
      // }, id);
      // console.log(google)
    },
    onScroll() {
      this.offsetTop = window.pageYOffset || document.documentElement.scrollTop
    },
    getRightSideBtnWidgetStyle(i) {
      const margin = i > 0 ? 15 * i : 0
      const coords = 250 * i - 125 * (this.rightButtonsWidgets.length - 1)
      return {
        zIndex: 1,
        right: '50px',
        top: `calc(50% - ${125}px + ${coords}px + ${margin}px)`,
        transform: `rotate(-90deg)`,
        transformOrigin: 'right top',
      }
    },
    getLeftSideBtnWidgetStyle(i) {
      const margin = i > 0 ? 15 * i : 0
      const coords = 250 * i - 125 * (this.leftButtonsWidgets.length - 1)
      return {
        zIndex: 1,
        left: '50px',
        top: `calc(50% - ${125}px + ${coords}px + ${margin}px)`,
        transform: `rotate(90deg)`,
        transformOrigin: 'left top',
      }
    },
    getSiteWidgetComponent(type) {
      switch (type) {
        case 'newsletter':
          return 'NewsletterWidget'
        case 'text':
          return 'TextWidget'
        case 'petitions_form':
          return 'PetitionWidget'
        case 'citizen_reports_form':
          return 'CitizenReportsWidget'
        case 'menu':
          return 'MenuWidget'
        case 'image':
          return 'ImageWidget'
        case 'gallery':
          return 'GalleryWidget'
        case 'poll':
          return 'PollWidget'
        case 'facebook':
          return 'FacebookWidget'
      }
    },
  },
}
</script>

<style lang="scss">
.goog-te-banner-frame, #google_translate_element2, .skiptranslate {
  display: none !important;
}

@media only screen and (min-width: 1981px) {
  .app-container {
    display: flex;
    gap: 1rem;
    padding: 0 15rem;
    flex-direction: row;
  }

  .app-sidebar {
    max-width: 20%;
  }

  .app-content {
    max-width: 60%;
    min-width: 60%;
    margin: 0 auto;
    width: 60%;
  }
}

@media only screen and (min-width: 1681px) and (max-width: 1980px) {
  .app-container {
    display: flex;
    gap: 1rem;
    padding: 0 8rem;
    flex-direction: row;
  }

  .app-sidebar {
    max-width: 20%;
  }

  .app-content {
    max-width: 60%;
    min-width: 60%;
  }
}

@media only screen and (min-width: 901px) and (max-width: 1680px) {
  .app-container {
    display: flex;
    gap: 1rem;
    padding: 0;
    flex-direction: row;
  }

  .app-sidebar {
    max-width: 20%;
  }

  .app-content {
    max-width: 60%;
    min-width: 60%;
  }
}

@media only screen and (max-width: 900px) {
  .app-container {
    display: flex;
    gap: 1rem;
    padding: 0;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .app-content {
    min-width: 100%;
  }

  .app-sidebar {
    min-width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .fr-view {
    max-width: 310px !important;
    width: 100% !important;
    word-break: break-word !important;
  }
}
</style>
