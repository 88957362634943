import configs from '../../configs'
import mutations from './mutations'

const { product, time, theme } = configs

const {
  isDarkTheme,
  menuTheme,
  toolbarTheme,
  isToolbarDetached,
  isContentBoxed,
  isRTL,
} = theme

// state initial values
const state = () => ({
  product,

  time,

  // themes and layout configurations
  isDarkTheme,
  menuTheme,
  toolbarTheme,
  isToolbarDetached,
  isContentBoxed,
  isRTL,
  tenant: null,
  siteKey: null,
  showEdit: false,
})

export default {
  namespaced: true,
  state,
  mutations,
}
