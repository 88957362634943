import time from './time'
import icons from './icons'
import theme from './theme'
import toolbar from './toolbar'
import locales from './locales'

import navigation from './navigation'

export default {
  // product display information
  product: {
    name: process.env.APP_NAME,
    version: process.env.APP_VERSION,
    domain: process.env.APP_BASE_URL,
    pusher_key: process.env.APP_MIX_PUSHER_APP_KEY,
    pusher_cluster: process.env.APP_MIX_PUSHER_APP_CLUSTER,
  },

  // time configs
  time,

  // icon libraries
  icons,

  // theme configs
  theme,

  // toolbar configs
  toolbar,

  // locales configs
  locales,

  // navigation configs
  navigation,
}
