export default {
  setData: (state, articles) => {
    state.articles = [...articles]
  },

  setShowData: (state, data) => {
    if (data.categories && data.categories.length) {
      state.categories = data.categories
    }
    if (data.articleTypes && data.articleTypes.length) {
      state.articleTypes = data.articleTypes
    }
    if (data.article && data.article.id) {
      state.article = { ...data.article }
    }
  },

  setResource: (state, data) => {
    state.article = { ...data }
  },
}
