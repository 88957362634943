export default {
  // main navigation - side menu
  menu: [
    {
      text: '',
      key: '',
      items: [
        {
          icon: 'mdi-home',
          key: 'menu.website',
          text: 'Website',
          link: '/',
        },
        {
          icon: 'mdi-view-dashboard',
          key: 'menu.dashboard',
          text: 'Dashboard',
          link: '/dashboard',
        },
        {
          icon: 'mdi-card-account-phone',
          key: 'menu.contact',
          text: 'Contact',
          link: '/contact',
        },
        {
          icon: 'mdi-account-multiple-check',
          key: 'menu.petitions',
          text: 'Petitions',
          link: '/petitions',
        },
        {
          icon: 'mdi-account-alert',
          key: 'menu.citizen_reports',
          text: 'Citizen Reports',
          items: [
            {
              icon: 'mdi-clipboard-list',
              key: 'menu.citizen_reports_list',
              text: 'Citizen Reports',
              link: '/citizen-reports',
              exact: true,
            },
            {
              icon: 'mdi-folder-multiple-plus',
              key: 'menu.citizen_reports_categories',
              text: 'Citizen Reports Categories',
              link: '/citizen-reports/categories',
              exact: true,
            },
          ],
        },
      ],
    },
    {
      text: 'Content Management',
      key: 'menu.content_management',
      items: [
        {
          icon: 'mdi-folder-multiple',
          key: 'menu.categories',
          text: 'Categories',
          items: [
            {
              icon: 'mdi-folder',
              key: 'menu.categories_index',
              text: 'Documents',
              link: '/categories',
              exact: true,
            },
            {
              icon: 'mdi-folder-multiple-plus',
              key: 'menu.categories_create',
              text: 'Documents',
              link: '/categories/create',
              exact: true,
            },
          ],
        },
        {
          icon: 'mdi-file-document-multiple',
          key: 'menu.articles',
          text: 'Articles',
          items: [
            {
              icon: 'mdi-file-document',
              key: 'menu.articles_index',
              text: 'Documents',
              link: '/articles',
              exact: true,
            },
            {
              icon: 'mdi-file-plus',
              key: 'menu.articles_create',
              text: 'Documents',
              link: '/articles/create',
              exact: true,
            },
            {
              icon: 'mdi-file-cog',
              key: 'menu.articles_types',
              text: 'Documents',
              link: '/articles/types',
              exact: true,
            },
          ],
        },
        {
          icon: 'mdi-widgets',
          key: 'menu.widgets',
          text: 'Widgets',
          items: [
            {
              icon: 'mdi-widgets-outline',
              key: 'menu.widgets_index',
              text: 'Widgets',
              link: '/widgets',
              exact: true,
            },
            {
              icon: 'mdi-plus-circle-outline',
              key: 'menu.widgets_create',
              text: 'Create Widgets',
              link: '/widgets/create',
              exact: true,
            },
            {
              icon: 'mdi-sort-descending',
              key: 'menu.widgets_sort',
              text: 'Sort Widgets',
              link: '/widgets/sort',
              exact: true,
            },
          ],
        },
        {
          icon: 'mdi-menu-open',
          key: 'menu.navigator',
          text: 'Navigator',
          link: '/navigator',
        },
        {
          icon: 'mdi-page-layout-header',
          key: 'menu.banner',
          text: 'Banner',
          link: '/banner',
        },
      ],
    },
    {
      text: 'Settings',
      key: 'menu.general_settings',
      items: [
        {
          icon: 'mdi-account-multiple',
          key: 'menu.users',
          text: 'Users',
          link: '/users',
        },
        {
          icon: 'mdi-shield-account-variant',
          key: 'menu.roles',
          text: 'Roles',
          link: '/roles',
        },
        {
          icon: 'mdi-graph',
          key: 'menu.departments',
          text: 'Departments',
          link: '/departments',
        },
      ],
    },
  ],
  // footer links
  footer: [
    // {
    //   icon: 'mdi-help-circle-outline',
    //   key: 'menu.utilityHelp',
    //   text: 'FAQs / Help',
    //   link: '/utility/help',
    // },
  ],
}
