export default {
  setErrors: (state, errors) => {
    if (typeof errors !== 'undefined') {
      state.errors = errors
    }
  },

  clearError: (state, field) => {
    if (state.errors[field]) {
      state.errors[field] = ''
    }
  },
}
