import mutations from './mutations'

const state = () => ({
  loading: false,
})

export default {
  state,
  mutations,
}
