<template>
  <v-app>
    <div class="pa-2 pa-md-4 full-with">
      <nuxt />
    </div>
  </v-app>
</template>

<script>
export default {
  auth: false,
}
</script>
