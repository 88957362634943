import mutations from './mutations'

const state = () => ({
  petitions: [],
  departments: [],
})

export default {
  namespaced: true,
  state,
  mutations,
}
