<template>
  <v-menu offset-y left transition="slide-y-transition" eager>
    <template #activator="{ on }">
      <v-btn icon v-on="on">
        <v-avatar size="40">
          <v-icon large>mdi-account-circle</v-icon>
        </v-avatar>
      </v-btn>
    </template>

    <!-- user menu list -->
    <v-list dense nav>
      <v-list-item
        v-for="(item, index) in menu"
        :key="index"
        :to="
          !index ? localePath(item.link + $auth.user.id) : localePath(item.link)
        "
        :exact="item.exact"
        :disabled="item.disabled"
        link
      >
        <v-list-item-icon>
          <v-icon small :class="{ 'grey--text': item.disabled }">
            {{ item.icon }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ item.key ? $t(item.key) : item.text }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-1"></v-divider>

      <v-list-item>
       <ThemeChanger></ThemeChanger>
      </v-list-item>
      <v-divider class="my-1"></v-divider>

      <v-list-item @click="logout">
        <v-list-item-icon>
          <v-icon small>mdi-logout-variant</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('menu.logout') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import config from '@/configs'
import ThemeChanger from '~/components/common/ThemeChanger'

export default {
  components: {ThemeChanger},
  data() {
    return {
      menu: config.toolbar.user,
    }
  },
  methods: {
    logout() {
      try {
        this.$auth.logout()
      } catch (e) {}
    },
  },
}
</script>
