export default {
  methods: {
    proceedWithDelete(haystack, needle) {
      haystack.forEach((item, index) => {
        if (needle === 'slot' && item.model === needle) {
          haystack.splice(index, 1)
        } else if (item.name === needle.name && item.model === needle.model) {
          haystack.splice(index, 1)
        }

        if (item.children && item.children.length > 0) {
          this.proceedWithDelete(item.children, needle)
        }
      })
    },
  },
}
