<template>
  <v-menu offset-y left transition="slide-y-transition">
    <template #activator="{ on }">
      <v-badge
        v-show="notifications.length"
        bordered
        :content="notifications.length"
        offset-x="22"
        offset-y="22"
      >
        <v-btn icon v-on="on">
          <v-icon>mdi-bell-outline</v-icon>
        </v-btn>
      </v-badge>
    </template>

    <!-- dropdown card -->
    <v-card max-height="600">
      <v-list three-line dense max-width="400">
        <v-subheader class="pa-2 font-weight-bold">Notificări</v-subheader>
        <div v-for="(item, index) in notifications" :key="index">
          <v-divider
            v-if="index > 0 && index < notifications"
            inset
          ></v-divider>

          <v-list-item @click="handleEvent(item)">
            <v-list-item-avatar size="32" :color="item.color">
              <v-icon dark small color="primary">{{ item.data.icon }}</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title
                v-text="item.data.action_text"
              ></v-list-item-title>
              <v-list-item-subtitle
                class="caption"
                v-text="item.data.message"
              ></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action class="align-self-center">
              <v-list-item-action-text
                v-text="item.time"
              ></v-list-item-action-text>
            </v-list-item-action>
          </v-list-item>
        </div>
      </v-list>
      <v-btn tile color="primary" block @click="readAllNotifications"
        >Golește Notificări</v-btn
      >
    </v-card>
  </v-menu>
</template>

<script>
export default {
  computed: {
    notifications() {
      return this.$auth.user && this.$auth.user.unread_notifications
        ? this.$auth.user.unread_notifications
        : []
    },
  },
  mounted() {
    // this.listenToAppUpdates()
    // this.listenToNotifications()
  },
  methods: {
    async handleEvent(item) {
      await this.$axios.post('/api/notifications/read', { id: item.id })
      await this.$auth.fetchUser()
      window.open(item.data.url)
    },
    async readAllNotifications() {
      await this.$axios.post('/api/notifications/read-all')
      await this.$auth.fetchUser()
    },
    listenToNotifications() {
      this.$echo
        .private('User.' + this.$auth.user.id + '.' + this.$auth.user.socket_id)
        .notification((notification) => {
          this.$toast.info(notification.message, {
            icon: notification.icon,
            iconPack: 'mdi',
            action: [
              {
                text: notification.action_text,
                onClick: (event, toastObject) => {
                  window.open(notification.url)
                  toastObject.goAway(0)
                },
              },
              {
                icon: 'mdi-close',
                onClick: (event, toastObject) => {
                  toastObject.goAway(0)
                },
              },
            ],
            duration: 0,
          })
          this.$auth.fetchUser()
        })
    },
    listenToAppUpdates() {
      this.$echo.channel('app-updates').listen('.app.updated', (e) => {
        this.$toast.success(e.message, {
          icon: 'mdi-application',
          iconPack: 'mdi',
          action: {
            text: 'Refresh',
            onClick: (event, toastObject) => {
              window.location.reload()
              toastObject.goAway(0)
            },
          },
          duration: 0,
        })
      })
    },
  },
}
</script>
