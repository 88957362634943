export default {
  startLoading(state) {
    if (process.client) {
      state.loading = true
    }
  },

  stopLoading(state) {
    if (process.client) {
      state.loading = false
    }
  },
}
