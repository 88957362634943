import mutations from './mutations'

const state = () => ({
  departments: [],
  manageDepartmentModal: false,
})

export default {
  namespaced: true,
  state,
  mutations,
}
