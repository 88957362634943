import locale77fa2464 from '../../translations/ro.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"ro"},
  vueI18nLoader: false,
  locales: [{"code":"ro","flag":"ro","name":"Română","file":"ro.js"},{"code":"en","flag":"us","name":"Engleză","file":"en.js"},{"code":"uk","flag":"ua","name":"Ucrainiană","file":"uk.js"},{"code":"hu","flag":"hu","name":"Maghiară","file":"hu.js"},{"code":"de","flag":"de","name":"Germană","file":"de.js"},{"code":"fr","flag":"fr","name":"Franceză","file":"fr.js"},{"code":"it","flag":"it","name":"Italiană","file":"it.js"},{"code":"es","flag":"es","name":"Spaniolă","file":"es.js"}],
  defaultLocale: "ro",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "/var/www/html/primarii-fe/translations",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  seo: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"ro","flag":"ro","name":"Română","file":"ro.js"},{"code":"en","flag":"us","name":"Engleză","file":"en.js"},{"code":"uk","flag":"ua","name":"Ucrainiană","file":"uk.js"},{"code":"hu","flag":"hu","name":"Maghiară","file":"hu.js"},{"code":"de","flag":"de","name":"Germană","file":"de.js"},{"code":"fr","flag":"fr","name":"Franceză","file":"fr.js"},{"code":"it","flag":"it","name":"Italiană","file":"it.js"},{"code":"es","flag":"es","name":"Spaniolă","file":"es.js"}],
  localeCodes: ["ro","en","uk","hu","de","fr","it","es"],
}

export const localeMessages = {
  'ro.js': () => Promise.resolve(locale77fa2464),
  'en.js': () => import('../../translations/en.js' /* webpackChunkName: "lang-en.js" */),
  'uk.js': () => import('../../translations/uk.js' /* webpackChunkName: "lang-uk.js" */),
  'hu.js': () => import('../../translations/hu.js' /* webpackChunkName: "lang-hu.js" */),
  'de.js': () => import('../../translations/de.js' /* webpackChunkName: "lang-de.js" */),
  'fr.js': () => import('../../translations/fr.js' /* webpackChunkName: "lang-fr.js" */),
  'it.js': () => import('../../translations/it.js' /* webpackChunkName: "lang-it.js" */),
  'es.js': () => import('../../translations/es.js' /* webpackChunkName: "lang-es.js" */),
}
