<template>
  <div class="d-flex flex-column flex-grow-1 relative">
    <video
      v-if="banner.video_url"
      :height="banner.on_top ? 650 : 550"
      width="100%"
      style="object-fit: cover"
      autoplay
      loop
      muted
      :class="{ pointer: banner.url }"
      class="hidden-sm-and-down"
      :src="banner.video_url"
      @click="openLink(banner.url)"
    ></video>
    <v-img
      v-else
      max-height="550"
      class="hidden-sm-and-down"
      cover
      alt="Banner"
      :class="{ pointer: banner.url }"
      :src="banner.image_url"
      @click="openLink(banner.url)"
    >
    </v-img>

    <div
      class="text-center white--text py-2 px-1"
      :class="{ 'pos-bottom absolute': banner.on_top }"
      style="width: 100%"
      :style="{
        backgroundColor: navigator.background_color,
      }"
    >
      <h5 class="mb-2">{{ banner.subtitle | uppercase }}</h5>
      <h1 class="text-h3 font-weight-bold">{{ banner.title }}</h1>
    </div>
    <edit-asset-button :link="`/banner`"></edit-asset-button>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import EditAssetButton from '~/components/common/EditAssetButton'

export default {
  name: 'CallToAction',
  components: { EditAssetButton },
  fetch() {
    return this.createResource('banners')
  },
  computed: {
    ...mapState('banners', ['banner']),
    ...mapState('navigators', ['navigator']),
  },
  methods: {
    ...mapActions('resources', ['createResource']),
    openLink(url) {
      if (url) {
        window.open(url)
      }
    },
  },
}
</script>
