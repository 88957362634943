import mutations from './mutations'

const state = () => ({
  roles: [],
  role: {},
  permissions: [],
})

export default {
  namespaced: true,
  state,
  mutations,
}
