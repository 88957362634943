<template>
  <v-card flat tile class="mb-2 pa-3" v-bind="$attrs">
    <v-card-title>
      <v-icon class="mr-1" x-large>mdi-email-newsletter</v-icon>
      <h2>{{ $t('fields.newsletter') }}</h2>
    </v-card-title>
    <v-card-text>
      {{ $t('newsletter_text') }}
    </v-card-text>
    <v-form ref="form" lazy-validation>
      <v-text-field
        v-model="newsletter.name"
        v-bind="$attrs"
        :label="$t('fields.name')"
        prepend-inner-icon="mdi-account"
        persistent-hint
        dense
        class="px-1"
        :error-messages="errors.name"
        :rules="[rules.required]"
        outlined
        :validate-on-blur="false"
        @change="clearError('name')"
      ></v-text-field>

      <v-text-field
        v-model="newsletter.email"
        v-bind="$attrs"
        :label="$t('fields.email')"
        prepend-inner-icon="mdi-email"
        persistent-hint
        :error-messages="errors.email"
        dense
        class="px-1"
        :rules="[rules.required, rules.email]"
        outlined
        :validate-on-blur="false"
        @change="clearError('email')"
      ></v-text-field>

      <v-checkbox
        v-model="newsletter.tos"
        class="px-1"
        :label="$t('fields.tos')"
        append-icon="mdi-open-in-new"
        @click:append="goToWidgetLink(null, null, data.url, 1)"
      ></v-checkbox>
    </v-form>
    <v-card-actions>
      <v-btn
        block
        color="primary"
        large
        :loading="loading"
        :disabled="!newsletter.tos || loading"
        @click="subscribe"
      >
        {{ $t('buttons.subscribe')
        }}<v-icon class="ml-1">mdi-send</v-icon></v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import goToWidgetLink from '~/mixins/goToWidgetLink'

export default {
  name: 'NewsletterWidget',
  mixins: [goToWidgetLink],
  data() {
    return {
      loading: false,
      newsletter: {
        name: '',
        email: '',
        tos: false,
      },
      rules: {
        required: (value) =>
          (value && Boolean(value)) || value === 0 || 'Câmp Obligatoriu',
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

          return pattern.test(value) || 'E-mail invalid.'
        },
      },
    }
  },
  computed: {
    ...mapState('errors', ['errors']),
  },
  beforeDestroy() {
    this.$recaptcha.destroy()
  },
  async mounted() {
    try {
      await this.$recaptcha.init()
    } catch (e) {
    }
  },
  methods: {
    ...mapMutations('errors', ['clearError']),
    ...mapActions('resources', ['storeResource']),
    async subscribe() {
      if (this.$refs.form.validate()) {
        this.loading = true
        const token = await this.$recaptcha.execute('subscribe_newsletter')
        await this.storeResource({
          scope: 'subscribers',
          navigate: false,
          data: { ...this.newsletter, token },
        })
        this.newsletter = {
          name: '',
          email: '',
          tos: false,
        }
        this.loading = false
      }
    },
  },
}
</script>
