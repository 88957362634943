import mutations from './mutations'

const state = () => ({
  widgets: [],
  widgetTypes: [
    {
      text: 'Buton lateral cu link',
      value: 'side_button',
    },
    {
      text: 'Text Footer',
      value: 'footer_text',
    },
    {
      text: 'Formular petiții',
      value: 'petitions_form',
    },
    {
      text: 'Formular sesizări',
      value: 'citizen_reports_form',
    },
    {
      text: 'Meniu',
      value: 'menu',
    },
    {
      text: 'Meniu Footer',
      value: 'footer_menu',
    },
    {
      text: 'Imagine cu link',
      value: 'image',
    },
    {
      text: 'Text cu editor',
      value: 'text',
    },
    {
      text: 'Galerie',
      value: 'gallery',
    },
    {
      text: 'Sondaj de opinie',
      value: 'poll',
    },
    {
      text: 'Pagină facebook',
      value: 'facebook',
    },
    {
      text: 'Newsletter',
      value: 'newsletter',
    },
  ],
  widget: {},
})

export default {
  namespaced: true,
  state,
  mutations,
}
