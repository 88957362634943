<template>
  <div class="show-edit-mode-btn">
    <v-scale-transition>
      <v-btn
        v-if="$auth.loggedIn"
        aria-label="Activează edit"
        color="warning"
        role="button"
        fab
        small
        class="pa-0"
        @click="toggleEditMode"
      >
        <v-icon>mdi-pencil-lock</v-icon>
      </v-btn>
    </v-scale-transition>
    <v-scale-transition>
      <v-btn
        v-if="$auth.loggedIn"
        aria-label="Către admin"
        color="warning"
        role="button"
        fab
        small
        nuxt
        :to="localePath('/categories')"
        class="pa-0"
      >
        <v-icon>mdi-shield-lock</v-icon>
      </v-btn>
    </v-scale-transition>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'AdvancesControls',
  methods: {
    ...mapMutations('app', ['toggleEditMode']),
  },
}
</script>

<style scoped>
.show-edit-mode-btn {
  position: fixed;
  bottom: 65px;
  right: 10px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
</style>
