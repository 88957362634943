<template>
  <v-card tile flat class="mb-2 overflow-hidden" v-bind="$attrs">
    <v-card-title class="font-weight-bold">
      {{ $t('menu.petitions') }}
    </v-card-title>
    <v-card-text class="px-2" v-text="$t('petitions.text')"> </v-card-text>
    <v-card-actions>
      <v-btn
        :to="localePath('/petitions/create?tos=' + url)"
        block
        color="warning"
        >{{ $t('buttons.petitions_form') }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'PetitionWidget',
  props: {
    url: {
      type: String,
      required: true,
    },
  },
}
</script>
