<template>
  <v-card
    v-show="renderPoll"
    tile
    flat
    class="pa-3 mb-2 overflow-hidden"
    v-bind="$attrs"
  >
    <v-card-title>
      <h2 class="py-2">Sondaj de opinie</h2>
    </v-card-title>
    <v-card-text>
      <client-only>
        <vue-poll v-if="renderPoll" v-bind="poll" @addvote="addVote" />
      </client-only>
    </v-card-text>
  </v-card>
</template>

<script>
import { isObject } from 'lodash'

export default {
  name: 'PollWidget',
  props: {
    pollData: {
      type: [Object, Array],
      default: null,
    },
  },
  data() {
    return {
      poll: null,
    }
  },
  computed: {
    renderPoll() {
      return (
        this.poll &&
        isObject(this.poll) &&
        this.poll.answers &&
        this.poll.question
      )
    },
  },
  mounted() {
    this.poll = JSON.parse(JSON.stringify(this.pollData))
  },
  methods: {
    addVote(vote) {
      console.log(vote)
    },
  },
}
</script>

<style scoped></style>
