export default {
  setData: (state, data) => {
    state.leftWidgets = [...data.leftWidgets]
    state.footerWidgets = [...data.footerWidgets]
    state.pageWidgets = [...data.pageWidgets]
    state.rightWidgets = [...data.rightWidgets]
    state.buttonWidgets = [...data.buttonWidgets]
  },

  setActiveMenuItem: (state, data) => {
    state.activeMenuItem = data
  }
}
