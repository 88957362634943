export default {
  setData: (state, categories) => {
    state.categories = [...categories]
  },

  setShowData: (state, data) => {
    state.categories = data.categories
    state.category = { ...data.category }
  },

  setResource: (state, data) => {
    state.category = { ...data }
  },
}
