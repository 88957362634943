<template>
  <v-app>
    <div class="d-flex flex-grow-1">
      <!-- Navigation -->
      <v-navigation-drawer
        v-model="drawer"
        app
        floating
        width="220px"
        :right="$vuetify.rtl"
        :light="menuTheme === 'light'"
        :dark="menuTheme === 'dark'"
      >
        <!-- Navigation menu info -->
        <template #prepend>
          <div class="pa-2">
            <div class="headline font-weight-bold text-uppercase primary--text">
              {{ product.name }}
            </div>
            <div class="overline grey--text">V {{ product.version }}</div>
          </div>
        </template>

        <!-- Navigation menu -->
        <main-menu :menu="navigation.menu" />

        <!-- Navigation menu footer -->
        <template #append>
          <!-- Footer navigation links -->
          <div
            v-for="(item, index) in navigation.footer"
            :key="index"
            class="pa-1 text-center"
          >
            <v-btn :to="item.link" small text block>
              <v-icon small>{{ item.icon }}</v-icon
              >{{ item.key ? $t(item.key) : item.text }}
            </v-btn>
          </div>

          <div class="px-2 text-center">
            <v-img
                alt="Imagine"
              class="mx-auto"
              :src="require('@/assets/images/icon.png')"
              width="200px"
            ></v-img>
          </div>
        </template>
      </v-navigation-drawer>

      <!-- Toolbar -->
      <v-app-bar
        app
        :color="isToolbarDetached ? 'transparent' : undefined"
        :flat="isToolbarDetached"
        :light="toolbarTheme === 'light'"
        :dark="toolbarTheme === 'dark'"
      >
        <v-card
          elevation="5"
          class="flex-grow-1 d-flex"
          :class="[isToolbarDetached ? 'pa-1 mt-3 mx-1' : 'pa-0 ma-0']"
          :flat="!isToolbarDetached"
        >
          <div class="d-flex flex-grow-1 align-center">
            <v-app-bar-nav-icon
              @click.stop="drawer = !drawer"
            ></v-app-bar-nav-icon>
            <v-row class="d-flex align-center flex-1 full-width">
              <v-col class="d-flex align-center">
                <img
                    alt="Logo"
                    class="px-2 py-1 d-inline-block pointer"
                    style="height: 50px"
                    :src="navigator.logo_url"
                />
                <div class="hidden-sm-and-down">{{ site }}</div>
              </v-col>
              <v-col class="d-flex align-center justify-end">
                <div :class="[$vuetify.rtl ? 'ml-1' : 'mr-1']">
                  <toolbar-language />
                </div>

                <div :class="[$vuetify.rtl ? 'ml-1' : 'mr-1']">
                  <toolbar-notifications />
                </div>

                <div :class="[$vuetify.rtl ? 'ml-1' : 'mr-1']">
              <span class="caption font-weight-bold d-md-inline-block d-none">
                {{ $auth.user.name }} |
                {{
                  $auth.user.department
                      ? $auth.user.department.name
                      : 'Super Admin'
                }}
              </span>
                  <toolbar-user />
                </div>
              </v-col>
            </v-row>

          </div>
        </v-card>
      </v-app-bar>

      <v-main>
        <v-container fluid>
          <v-row no-gutters>
            <v-col>
              <nuxt :nuxt-child-key="$route.fullPath" />
            </v-col>
          </v-row>
        </v-container>
        <v-footer dark app inset padless>
          <v-spacer></v-spacer>
          <div class="overline">Dezvoltat de Hirama Tech</div>
        </v-footer>
      </v-main>
    </div>
  </v-app>
</template>

<script>
import {mapActions, mapState} from 'vuex'

import config from '../configs'

import MainMenu from '../components/navigation/MainMenu'
import ToolbarUser from '../components/toolbar/ToolbarUser'
import ToolbarLanguage from '../components/toolbar/ToolbarLanguage'
import ToolbarNotifications from '../components/toolbar/ToolbarNotifications'

export default {
  name: 'DefaultLayout',
  components: {
    MainMenu,
    ToolbarUser,
    ToolbarLanguage,
    ToolbarNotifications,
  },
  middleware: 'auth',
  data() {
    return {
      drawer: null,
      navigation: config.navigation,
    }
  },
  fetch() {
    return this.createResource('navigators')
  },
  computed: {
    ...mapState('navigators', ['navigator']),
    ...mapState('app', ['site']),
    ...mapState('app', [
      'product',
      'isContentBoxed',
      'menuTheme',
      'toolbarTheme',
      'isToolbarDetached',
    ]),
  },
  methods: {
    ...mapActions('resources', ['createResource']),
  }

}
</script>
