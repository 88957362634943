<template>
  <v-app>
    <v-main>
      <v-container fluid fill-height class="pa-0">
        <nuxt />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'Map',
  auth: false,
}
</script>
