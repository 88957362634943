<template>
  <v-fade-transition>
    <v-btn
      v-show="showComponent"
      :block="block"
      color="warning"
      x-small
      tile
      :to="localePath(link)"
      >Edit</v-btn
    >
  </v-fade-transition>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'EditAssetButton',
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    link: {
      type: String,
      required: true,
    },
    block: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState('app', ['showEdit']),
    showComponent() {
      return this.show && this.$auth.loggedIn && this.showEdit
    },
  },
}
</script>
