<template>
  <v-tooltip bottom>
    <template #activator="{ on }">
      <v-btn aria-label="Temă dark sau light" :dark="theme" icon class="mr-1" @click="theme = !theme" v-on="on">
        <v-icon v-if="!theme" :color="changeToWhite ? 'white' : 'initial'">mdi-brightness-4</v-icon>
        <v-icon v-else>mdi-brightness-6</v-icon>
      </v-btn>
    </template>
    <span>{{ $t('fields.theme') }}</span>
  </v-tooltip>
</template>

<script>

export default {
  name: 'ThemeChanger',
  props: {
    changeToWhite: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    theme: {
      set(val) {
        this.$store.commit('app/setGlobalTheme', val)
      },
      get() {
        return this.$store.state.app.isDarkTheme
      },
    },
  },
  mounted() {
    const theme = localStorage.getItem('theme') === 'true'
    if (theme !== undefined) {
      this.theme = theme
    }
  },

}
</script>

<style scoped>

</style>
