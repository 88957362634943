import mutations from './mutations'

const state = () => ({
  users: [],
  departments: [],
  roles: [],
  user: {},
})

export default {
  namespaced: true,
  state,
  mutations,
}
