var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"main-nav__container transition-animations",style:({
      backgroundColor: _vm.navigator.background_color,
    })},[_c('img',{staticClass:"px-2 py-1 d-inline-block pointer",attrs:{"height":"70","width":"auto","alt":"Logo","src":_vm.navigator.logo_url},on:{"click":function($event){return _vm.goTo(null)}}}),_vm._v(" "),_vm._l((_vm.navigator.items),function(nav){return [(nav.children && nav.children.length > 1)?_c('v-menu',{key:nav.id,attrs:{"open-on-hover":"","bottom":"","offset-y":"","tile":"","allow-overflow":"","origin":"top center","attach":".main-nav__container","transition":"slide-y-transition","content-class":"main-nav__menu-item overflow-auto"},on:{"input":function($event){_vm.hoveredItem = { children: [] }}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var hover = ref.hover;
return [_c('div',_vm._g({staticClass:"main-nav__item transition-animations",style:({
                backgroundColor: hover
                  ? _vm.getHoverLinkColor(nav.color)
                  : 'initial',
              }),on:{"click":function($event){return _vm.goTo(nav)}}},on),[_c('span',[_vm._v(" "+_vm._s(_vm._f("uppercase")(nav.name)))])])]}}],null,true)})]}}],null,true)},[_vm._v(" "),_c('v-card',{staticClass:"relative",attrs:{"flat":"","dark":"","color":_vm.getHoverLinkColor(nav.color),"tile":"","width":"100%"}},[_c('div',{staticClass:"main-nav__icon__container"},[_c('v-icon',{staticClass:"main-nav__icon",attrs:{"color":"white"}},[_vm._v("\n              "+_vm._s(_vm.hoveredItem.icon
                  ? ("mdi-" + (_vm.hoveredItem.icon))
                  : ("mdi-" + (nav.icon))))])],1),_vm._v(" "),_c('v-row',{attrs:{"no-gutters":"","dense":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('ul',{staticClass:"main-nav__left-menu"},[_vm._l((nav.children),function(item){return [(item.name)?_c('li',{key:("children-" + (item.id)),on:{"mouseover":function($event){_vm.hoveredItem = item},"click":function($event){return _vm.goTo(item)}}},[_vm._v("\n                    "+_vm._s(item.name)+"\n                    "),(item.url)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-open-in-new")]):_vm._e()],1):_vm._e()]})],2)]),_vm._v(" "),(
                _vm.hoveredItem.id &&
                _vm.hoveredItem.children &&
                _vm.hoveredItem.children.length
              )?_c('v-col',[_c('ul',{staticClass:"main-nav__right-menu"},[_vm._l((_vm.hoveredItem.children),function(child){return [(child.name)?_c('li',{key:("last-children-" + (child.id)),on:{"click":function($event){return _vm.goTo(child)}}},[_vm._v("\n                    "+_vm._s(child.name)+"\n                    "),(child.url)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-open-in-new")]):_vm._e()],1):_vm._e()]})],2)]):_vm._e()],1)],1)],1):_c('v-hover',{key:nav.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var hover = ref.hover;
return [_c('div',{staticClass:"main-nav__item transition-animations",style:({
            backgroundColor: hover ? _vm.getHoverLinkColor(nav.color) : 'initial',
          }),on:{"click":function($event){return _vm.goTo(nav)}}},[_c('span',[_vm._v(" "+_vm._s(_vm._f("uppercase")(nav.name)))])])]}}],null,true)})]}),_vm._v(" "),_c('ToolbarLanguage',{attrs:{"attach":".main-nav__container"}}),_vm._v(" "),_c('ThemeChanger',{attrs:{"change-to-white":""}}),_vm._v(" "),_c('v-menu',{attrs:{"offset-y":"","left":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-magnify")])],1)]}}])},[_vm._v(" "),_c('v-card',{staticClass:"pa-2",attrs:{"width":"400px"}},[_c('v-card-text',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('fields.search'),"outlined":"","hint":_vm.$t('fields.search_hint')},on:{"click:append":_vm.searchArticle,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchArticle.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":"","color":"primary","large":"","aria-label":"Căutare site","block":"","nuxt":"","to":_vm.localePath('/articles/search?filters=true')}},[_vm._v(_vm._s(_vm.$t('buttons.advanced_search')))])],1)],1)],1),_vm._v(" "),_c('edit-asset-button',{staticClass:"main-nav__item",attrs:{"block":false,"link":"/navigator"}})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }