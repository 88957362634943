<template>
  <div class="text-center">
    <v-bottom-sheet v-model="showCookie" inset persistent eager :open-on-click="false" :retain-focus="false">
      <v-sheet class="text-center pa-3" height="200px">
        <v-btn text color="success" @click="acceptCookies">
          {{ $t('buttons.accept') }}
        </v-btn>
        <div class="my-3">{{ $t('cookie.text') }}</div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
export default {
  name: 'CookiePolicy',
  data() {
    return {
      showCookie: false,
    }
  },
  mounted() {
    const cookie = localStorage.getItem('cookie-accepted')
    if (!cookie) {
      this.showCookie = true
    }
  },
  methods: {
    acceptCookies() {
      this.showCookie = false
      localStorage.setItem('cookie-accepted', '1')
    },
  },
}
</script>
