function saveFilters(data) {
  if (process.client) {
    localStorage.setItem(window.location.href, JSON.stringify(data))
  }
}

export default {
  setFilter: (state, data) => {
    state.queryParams[data.scope][data.key] = data.val
    saveFilters(state.queryParams[data.scope])
  },

  resetScopeFilters: (state, scope) => {
    state.queryParams[scope] = { ...state.defaultParams[scope] }
    saveFilters(state.queryParams[scope])
  },

  setResourcePagination: (state, { scope, pagination }) => {
    state.queryParams[scope].pagination.itemsPerPage = pagination.itemsPerPage
    state.queryParams[scope].pagination.page = pagination.page
    saveFilters(state.queryParams[scope])
  },

  setTotalItems: (state, { scope, total }) => {
    state.queryParams[scope].pagination.total = total
    saveFilters(state.queryParams[scope])
  },

  setItemsPerPage: (state, { scope, itemsPerPage }) => {
    state.queryParams[scope].pagination.itemsPerPage = itemsPerPage
    saveFilters(state.queryParams[scope])
  },
}
