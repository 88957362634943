export default {
  resetScopeFilters: ({ commit, dispatch }, payload) => {
    commit('resetScopeFilters', payload.scope)
    if (payload.fetch) {
      dispatch('resources/getData', payload.scope, { root: true })
    }
  },

  setVuetifyPagination: ({ commit, dispatch }, data) => {
    const { itemsPerPage, page, ...vuetify } = { ...data.pagination }

    let sort = ''
    if (vuetify.sortBy && vuetify.sortBy.length) {
      sort = vuetify.sortBy[0]
      if (sort && vuetify.sortDesc && vuetify.sortDesc.length) {
        sort = vuetify.sortDesc[0] ? '-' + sort : sort
      }
    }

    commit('setFilter', {
      key: 'sort',
      scope: data.scope,
      val: sort,
    })

    commit('setResourcePagination', {
      scope: data.scope,
      pagination: {
        itemsPerPage,
        page,
      },
    })
  },
}
