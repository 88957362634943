export default {
  cookie: {
    text: 'Prin utilizarea serviciilor noastre, îți exprimi acordul cu privire la faptul că folosim module cookie și alte tehnologii similare în scopul îmbunătățirii și al personalizării conținutului nostru, în vederea analizării traficului, precum și împotriva utilizării neautorizate.',
  },
  petitions: {
    text: 'Pentru a trimite o petiție vă rugăm accesați formularul printr-un click pe butonul de mai jos. (Prin Petiție se înţelege cererea, reclamaţia sau' +
        ' propunerea formulată în scris ori prin postă electronică, pe care un cetăţean sau o organizaţie legal constituită o poate adresa Primăriei)',
    image_hint: 'Se pot încărca doar imagini cu dimensiune maximă de 10MB.',
    form_subtitle:
      'Prin PETIŢIE se înţelege cererea, reclamaţia, sesizarea sau propunerea formulată în scris ori prin postă electronică, pe care un cetăţean sau o organizaţie legal constituită o poate adresa autorităţilor şi instituţiilor publice centrale şi locale, serviciilor publice descentralizate ale ministerelor şi ale celorlalte organe centrale, companiilor şi societăţilor naţionale, societăţilor comerciale de interes judeţean sau local, precum şi regiilor autonome, denumite în continuare autorităţi si instituţii publice. (art. 2 din Ordonanţa Guvernului nr. 27/2002 privind reglementarea activităţii de soluţionare a petiţiilor cu modificările ulterioare).',
  },
  citizen_reports: {
    text: 'Pentru a trimite o sesizare vă rugăm accesați formularul printr-un click pe butonul de mai jos. (Sesizarea se referă la un fapt sau situație constatată  pe teritoriul localității, care trebuie aduse la cunoștiință Primăriei)',
    hint: 'Alegeți o categorie pentru a putea plasa pin pe hartă',
  },
  hirama: 'Un produs dezvoltat de Hirama Tech',
  menu_warning: 'Atenție! Entitatea face deja parte din meniu',
  validation_error: 'Atenție! Anumite câmpuri sunt invalide.',
  menu_remove_warning:
    'Sunteți sigur(ă) că doriți ștergerea entității din meniu?',
  newsletter_text:
    'Abonează-te pentru a fi la curent cu informațiile din localitatea ta',
  cropper: {
    zoom_in: 'Apropie',
    zoom_out: 'Îndepărtează',
    flip_horizontal: 'Întoarce pe orizontală',
    flip_vertical: 'Întoarce pe verticală',
    rotate_right: 'Întoarce spre dreapta',
    rotate_left: 'Întoarce spre stânga',
  },
  poll: {
    question: 'Subiectul Sondajului',
    answer: 'Variantă răspuns',
    title: 'Sondaj',
    add: 'Adaugă variantă răspuns',
    finished: 'Finalizează sondajul și afișează rezultatul',
    active: 'Finalizează sondajul și ascunde rezultatul',
    multiple: 'Variante multiple la votare',
  },
  tooltips: {
    refresh: 'Resetare Filtre',
  },
  common: {
    faq: 'FAQ / Ajutor',
    search: 'Căutare',
    contact: 'aici.',
  },
  departments: {
    edit: 'Editează Departamentul',
    create: 'Departament Nou',
  },
  citizen_reports_category: {
    edit: 'Editează Categoria',
    create: 'Categorie Nouă',
  },
  buttons: {
    subscribe: 'Abonează-mă',
    advanced_search: 'Căutare avansată',
    petitions_form: 'Formular de petiții',
    citizen_reports_form: 'Formular de sesizări',
    create_navigator: 'Navigator Nou',
    create_widget: 'Widget Nou',
    create_article_type: 'Tip Articol Nou',
    create_category: 'Categorie Nouă',
    create_role: 'Rol Nou',
    create_article: 'Articol Nou',
    create_user: 'Utilizator Nou',
    create_department: 'Departament Nou',
    back: 'Înapoi',
    pin: 'Plasează Pin',
    save: 'Salvează',
    accept: 'Acceptă și închide',
    send: 'Trimite petiția',
    filter: 'Aplică filtre',
    table: 'Editează coloane tabel',
  },
  fields: {
    subscribe_newsletter: 'Abonați-vă la Newsletter',
    order_home: 'Ordinea pe pagina principală',
    article_category: 'Categoria Articolului',
    menu_widget: 'Configurare Meniu',
    link_in_new_tab: 'Linkul categoriei se deschide în fereastră nouă',
    side_button_widget: 'Configurare Buton Lateral',
    newsletter_widget: 'Configurare Newsletter',
    petitions_widget: 'Configurare Formular Petiții',
    pick_navigator_type: 'Alege tipul nodului principal',
    gallery: 'Galerie foto',
    article: 'Articol',
    text: 'Text',
    body: 'Conținut',
    filters: 'Alegeți filtre',
    button_color: 'Culoare Buton',
    cover_image_location: 'Poziționare imagine articol',
    cover_image_location_hint:
      'Poziționare imageine articol la pagina de vizualizare articol',
    search: 'Caută articole',
    footer_image: 'Imagine Footer',
    petition_email: 'Emailul responsabilului pentru petiții',
    background_color: 'Fundal footer și navigator',
    first_name: 'Prenume',
    citizen_reports_widget: 'Configurare Formular Sesizări',
    message: 'Mesaj',
    search_hint: 'Tastați ENTER sau faceți click pe lupa ptr. căutare',
    on_top: 'Mesajul peste banner',
    check: 'Marchează ca rezolvat',
    phone: 'Nr. Telefon',
    theme: 'Temă dark/light',
    image_link: 'Link Imagine',
    no_image: 'Fără Imagine',
    last_name: 'Nume',
    banner: 'Imagine Banner',
    no_location: 'Alege tipul widgetului',
    subtitle: 'Subtitlu',
    video: 'Video',
    tos_url: 'Link către articolul despre prelucrarea datelor personale',
    location_footer: 'Footer',
    location_page: 'Pagină',
    current_coa: 'Stemă curentă',
    current_video: 'Video curent',
    node: 'Nodul principal',
    location: 'Amplasare',
    icon: 'Iconiță',
    tos: 'Sunt de acord cu prelucrarea datelor personale',
    color: 'Culoare',
    coa: 'Stema',
    location_left: 'Partea stângă',
    location_right: 'Partea dreaptă',
    start_typing: 'Începe să scrii pentru a căuta',
    completion: 'Procent Realizare',
    facebook_url: 'URL-ul paginii de facebook',
    publish_date_hint:
      'Data de la care articolul va apărea pe site. Pentru' +
      ' a aparea imediat lăsați gol',
    cover_image: 'Imagine articol',
    no_date: 'Fără dată afișată',
    display_date: 'Data afișată',
    display_time: 'Data și ora afișată',
    pick_article_type: 'Alege tipul articolului',
    pick_widget_type: 'Alege tipul widgetului',
    publish_date: 'Data publicării',
    vote: 'Votează',
    current_image: 'Imagine curentă',
    image: 'Imagine',
    short_description: 'Descriere scurtă',
    recommended: 'Articol newsletter',
    category: 'Categorie',
    article_type: 'Tipul Articolului',
    title: 'Titlu',
    pick_category: 'Alege categoria',
    blocks: 'Blocuri',
    url: 'URL',
    show_on_home: 'Apare pe prima pagină',
    layout: 'Mod afișare',
    order: 'Ordine',
    newsletter: 'Newsletter',
    facebook: 'Facebook',
    user_id: 'Creeată de',
    permissions: 'Permisiuni',
    deactivate: 'Dezactivează Contul',
    activate: 'Activează Contul',
    interval: 'Interval Dată',
    user: 'Utilizator',
    user_name: 'Nume utilizator',
    id: 'ID',
    email: 'Email',
    active: 'Activ',
    name: 'Nume',
    role: 'Rol',
    position: 'Funcție',
    created: 'Dată Creare',
    actions: 'Acțiuni',
    delete: 'Șterge',
    department: 'Departament',
    password: 'Parolă',
    new_password: 'Parola nouă',
    account: 'Detalii Cont',
    info: 'Informații',
    basic_info: 'Informații de bază',
    raw_data: 'Informații JSON',
    number: 'Număr Regsitratură Generală',
    metadata: 'Meta Informații',
    last_login: 'Ultima logare',
    last_password_reset: 'Resetare parolă',
    remove_admin: 'Scoateți accesul de admin',
    give_admin: 'Faceți admin',
    danger: 'Atenție',
    edit_avatar: 'Editează avatar',
    created_at: 'Data Creării',
    description: 'Conținut',
    project: 'Detalii Proiect',
    before: 'Înainte',
    after: 'După',
    status: 'Stare',
    users: 'Utilizatori',
    map_setup: 'Configurare Hartă',
    attachment_observation: 'Detalii fișiere',
    type: 'Tip',
  },
  prompt: {
    yes: 'Da',
    no: 'Nu',
  },
  contact: {
    form_message: 'Formular de contact',
    form_title: 'Completați formularul și vă vom contacta',
    message: 'Date de contact',
    title: 'Pentru urgențe nu ezitați să ne contactați direct',
    message_field: 'Mesaj / Întrebare',
    button: 'Trimite',
    faq: 'Poate găsești răspunsul la întrebare în secțiunea de ajutor',
  },
  text: {
    reset_password_link: 'Trimiteți un email cu link pentru resetarea parolei.',
    delete_user_waning:
      'Înainte de a dezactiva utilizatorul, asigurați-vă ca i-ați atribuit înregistrările altui utilizator.',
  },
  reset: {
    title: 'Setați o nouă parolă',
    status: 'Resetarea parolei',
    button: 'Resetează Parola și Loghează-mă',
  },
  forgot: {
    title: 'Ți-ai uitat parola?',
    subtitle:
      'Introduceți adresa de email și o să vă trimitem un link pentru resetarea parolei.',
    button: 'Resetează Parola',
    backtosign: 'Înapoi la Logare',
  },
  copy: {
    text: 'Copiază',
    done: 'Copiat!',
  },
  login: {
    title: 'Intră in cont',
    message: 'Bine ați venit',
    motto: 'Administrare Platformă Primării',
    button: 'Logare',
    forgot: 'Ți-ai uitat parola?',
  },
  articles: {
    general_info: 'Informații generale',
    publish_date_hint: 'Data la care articolul va fi afișat pe site',
    show_on_home_hint: 'Articolul va apărea pe pagina principală',
    recommended_hint:
      'Bifați dacă doriți trimiterea acestui articol abonaților la newsletter',
  },
  utility: {
    maintenance_title: 'În mentenanță',
    maintenance_text: 'Revenim în curând',
  },
  faq: {
    call: 'Aveți alte întrebări? Nu ezitați să ne contactați ',
  },
  menu: {
    citizen_reports: 'Sesizări',
    petitions: 'Petiții',
    banner: 'Banner și Footer',
    citizen_reports_list: 'Listare Sesizări',
    citizen_reports_categories: 'Categorii Sesizări',
    navigator: 'Navigator',
    widgets: 'Widgeturi',
    widgets_index: 'Listă Widgeturi',
    widgets_create: 'Adaugă Widget',
    widgets_sort: 'Sortare Widgeturi',
    content_management: 'Administrare Conținut',
    article_types: 'Tipuri articol',
    articles: 'Articole',
    articles_index: 'Listă Articole',
    articles_create: 'Adaugă Articol',
    articles_types: 'Tipuri Articole',
    categories_index: 'Listă Categorii',
    categories_create: 'Adaugă Categorie',
    roles: 'Roluri',
    categories: 'Categorii',
    contact: 'Contact',
    departments: 'Departamente',
    home: 'Pagina principală',
    general_settings: 'Setări Aplicație',
    users: 'Utilizatori',
    logout: 'Delogare',
    profile: 'Profil',
    utilityHelp: 'FAQs / Ajutor',
    website: 'Către website',
    dashboard: 'Dashboard',
    support: 'Suport',
  },
  media_uploader: {
    documents: 'Atașamente',
    album: 'Galerie Foto',
  },
  form_subtitle: {
    create: 'Formular de creare',
    edit: 'Formular de editare',
  },
  articleTypes: {
    create: 'Adaugă tip articol',
    edit: 'Editează tip articol',
  },
  users: {
    create_form_title: 'Adaugă utilizator',
    edit_form_title: 'Editează utilizatorul',
    edit_profile: 'Editează profilul utilizatorului',
    my_profile: 'Profilul Meu',
  },
  roles: {
    create_form_title: 'Adaugă rol',
    edit_form_title: 'Editează rolul',
  },
  article: {
    create_form_title: 'Adaugă articol',
    edit_form_title: 'Editează articolul',
  },
  navigator: {
    create_form_title: 'Configurare navigator',
  },
  category: {
    create_form_title: 'Adaugă categorie',
    edit_form_title: 'Editează categoria',
  },
  banner: {
    create_form_title: 'Configurare Banner',
  },
  footer: {
    create_form_title: 'Configurare Footer',
  },
  widget: {
    order_hint:
      'Un număr de ordine mai mare înseamnă că widgetul va aparea mai sus pe frontend',
    create_form_title: 'Adaugă widget',
    edit_form_title: 'Editează widgetul',
  },
  // Vuetify components translations
  $vuetify: {
    badge: 'Insignă',
    close: 'Închide',
    dataIterator: {
      noResultsText: 'Nu s-au găsit înregistrări conform căutărilor',
      loadingText: 'Se încarcă date...',
    },
    dataTable: {
      itemsPerPageText: 'Linii per pagină:',
      ariaLabel: {
        sortDescending: 'Sortate descrescător.',
        sortAscending: 'Sortate crescător.',
        sortNone: 'Nesortate.',
        activateNone: 'Dezactivați sortarea.',
        activateDescending: 'Activați sortarea crescătoare.',
        activateAscending: 'Activați sortarea descrescătoare.',
      },
      sortBy: 'Sortați după',
    },
    dataFooter: {
      itemsPerPageText: 'Iteme per pagină:',
      itemsPerPageAll: 'Toate',
      nextPage: 'Pagina următoare',
      prevPage: 'Pagina precedentă',
      firstPage: 'Prima pagină',
      lastPage: 'Ultima pagină',
      pageText: '{0}-{1} of {2}',
    },
    datePicker: {
      itemsSelected: '{0} selectate',
      nextMonthAriaLabel: 'Luna următoare',
      nextYearAriaLabel: 'Anul următor',
      prevMonthAriaLabel: 'Luna precedentă',
      prevYearAriaLabel: 'Anul precedent',
    },
    noDataText: 'Nu există date disponibile',
    carousel: {
      prev: 'Imaginea anterioară',
      next: 'Imaginea următoare',
      ariaLabel: {
        delimiter: 'Imaginea {0} din {1}',
      },
    },
    calendar: {
      moreEvents: '{0} mai multe',
    },
    fileInput: {
      counter: '{0} fișiere',
      counterSize: '{0} fișiere ({1} total)',
    },
    timePicker: {
      am: 'AM',
      pm: 'PM',
    },
    pagination: {
      ariaLabel: {
        wrapper: 'Paginație Navigare',
        next: 'Pagina următoare',
        previous: 'Pagina precedentă',
        page: 'Vezi Pagina {0}',
        currentPage: 'Pagina curentă, Pagina {0}',
      },
    },
  },
}
