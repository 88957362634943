export default {
  setGlobalTheme(state, theme) {
    state.isDarkTheme = theme
    this.app.vuetify.framework.theme.dark = state.isDarkTheme
    localStorage.setItem('theme', theme)
  },
  setRTL: (state, isRTL) => {
    this.$vuetify.framework.rtl = isRTL
    state.isRTL = isRTL
  },
  setContentBoxed: (state, isBoxed) => {
    state.isContentBoxed = isBoxed
  },
  setMenuTheme: (state, theme) => {
    state.menuTheme = theme
  },
  setToolbarTheme: (state, theme) => {
    state.toolbarTheme = theme
  },
  setTimeZone: (state, zone) => {
    state.time.zone = zone
  },
  setTimeFormat: (state, format) => {
    state.time.format = format
  },
  setToolbarDetached: (state, isDetached) => {
    state.isToolbarDetached = isDetached
  },
  setTenantData: (state, tenant) => {
    state.tenant = tenant.domain
    state.site = tenant.site
    state.siteKey = tenant.siteKey
    state.analyticsId = tenant.analyticsId
  },
  toggleEditMode: (state) => {
    state.showEdit = !state.showEdit
  },
}
