<template>
  <v-card
    tile
    flat
    class="mb-2 py-2 px-1"
    v-bind="$attrs"
    :color="getColor"
    :dark="!!theme"
  >
    <div class="title">
      {{ data.name }}
    </div>
    <v-treeview v-if="menu" dense hoverable :items="menu" color="primary">
      <template #label="{ item }">
        <div
            v-if="item.model !== 'slot'"
          aria-label="Vezi categorie"
          class="menu-link"
          :class="{'active-menu-item': activeMenuItem === item.name + data.name}"
          @click="activateNode(item, data.name)"
        >
          {{ item.name }}
        </div>
        <div v-else style="height: 0"></div>
      </template>
      <template #append="{ item }">
        <v-icon v-if="item.url" small>mdi-open-in-new</v-icon>
      </template>
    </v-treeview>
  </v-card>
</template>
<script>
import { mapMutations} from 'vuex'
import goToWidgetLink from '~/mixins/goToWidgetLink'
import DeleteNodes from '~/mixins/recursive-delete'
export default {
  name: 'MenuWidget',
  mixins: [goToWidgetLink, DeleteNodes],
  props: {
    data: {
      type: Object,
      required: true,
    },
    opacity: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: null,
    }
  },
  computed: {
    activeMenuItem: {
      set(val) {
        return this.setActiveMenuItem(val)
      },
      get()  {
        return this.$store.state.homeWidgets.activeMenuItem
      }
    },
    theme() {
      return this.$store.state.app.isDarkTheme
    },
    getColor() {
      if (this.opacity) return 'transparent'
      return ''
    },
  },
  mounted() {
    this.menu = this.$clone(this.data.menu)
    this.proceedWithDelete(this.menu, 'slot')
  },
  methods: {
    ...mapMutations('homeWidgets', ['setActiveMenuItem']),
    activateNode(item, categoryName) {
      this.activeMenuItem = item.name + categoryName
      this.goToWidgetLink(item.model, item.slug, item.url, item.link_in_new_tab, item.model_id)
    }
  },
}
</script>

<style lang="scss">
.menu-link {
  cursor: pointer;
  font-size: clamp(0.8rem, 1rem + 60vw, 1rem);
  word-break: break-word !important;
}

.v-treeview-node__level {
  width: 25px !important;
}

.v-treeview-node__content {
  margin-left: 0 !important;
}

.v-treeview--dense .v-treeview-node__root {
  min-height: 25px !important;
}

.v-treeview-node__content > .v-treeview-node__label > .active-menu-item {
  color: var(--v-primary-base) !important;
}
</style>
