<template>
  <v-card
    v-if="error.statusCode === 404"
    class="text-center w-full error-page pa-3"
  >
    <v-img
        alt="Ilustrație"
      src="/images/illustrations/404-illustration.svg"
      max-height="400"
      contain
    />
    <div class="display-2 mt-10">Cum ai ajuns aici?</div>
    <div class="mt-3 mb-6">
      Ne pare rău, se pare că nu găsim pagina pe care o căutați.
    </div>
    <v-btn href="/" block large color="primary">Trimite-mă Înapoi</v-btn>
  </v-card>
  <v-card v-else class="text-center w-full error-page pa-3">
    <v-img
        alt="Ilustrație"
      src="/images/illustrations/500-illustration.svg"
      max-height="400"
      contain
    />
    <div class="display-2 mt-10">OOPS! Am întâmpinat o eroare!</div>
    <div class="mt-3 mb-10">Experții noștrii lucrează să rezolve problema.</div>
    <v-btn href="/" block large color="primary">Trimite-mă Înapoi</v-btn>
  </v-card>
</template>

<script>
export default {
  layout: 'simple',
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style>
.error-page {
  max-width: 500px;
}
</style>
