export default {
  // apps quickmenu
  apps: [
    {
      icon: 'mdi-clipboard-file-outline',
      text: 'Input Record',
      key: 'registry.in_record',
      subtitle: 'Creează Înregistrare',
      link: '/registry/create/in-record',
    },
    {
      icon: 'mdi-clipboard-file-outline',
      text: 'Output Record',
      key: 'registry.out_record',
      subtitle: 'Creează Înregistrare',
      link: '/registry/create/out-record',
    },
    {
      icon: 'mdi-calendar-clock',
      text: 'Profile',
      key: 'registry.reserve_number',
      subtitle: '',
      link: '',
    },
    {
      icon: 'mdi-help-circle-outline',
      text: 'Contact',
      key: 'registry.contact',
      subtitle: 'Date de contact',
      link: '/',
    },
  ],

  // user dropdown menu
  user: [
    {
      icon: 'mdi-account-box-outline',
      key: 'menu.profile',
      text: 'Profile',
      link: '/users/profile/',
    },
  ],
}
