export default ({ app }, inject) => {
  inject('successAlert', (message = 'Acțiunea s-a efectuat cu succes.') => {
    return app.$swal({
      title: 'Succes!',
      text: message,
      timer: 1000,
      showConfirmButton: false,
      icon: 'success',
    })
  })

  inject('warningAlert', (message = 'Vrei să continui?') => {
    return app.$swal({
      title: 'Atenție!',
      text: message,
      icon: 'warning',
      confirmButtonText: app.i18n.locale === 'ro' ? 'Da' : 'Yes',
      cancelButtonText: app.i18n.locale === 'ro' ? 'Nu' : 'No',
      showConfirmButton: true,
      showCancelButton: true,
    })
  })

  inject('errorAlert', (message = 'Am întâmpinat o eroare.') => {
    return app.$swal({
      title: 'Eroare!',
      text: message,
      icon: 'error',
    })
  })

  inject('textPrompt', (message = 'Vrei să continui?', label = '') => {
    return app.$swal({
      text: message,
      input: 'text',
      inputLabel: label,
      inputValue: '',
      confirmButtonText: 'Salvează',
      cancelButtonText: 'M-am răzgândit',
      showConfirmButton: true,
      showCancelButton: true,
    })
  })
}
