<template>
  <v-menu
      offset-y
      transition="slide-y-transition"
      tile
      eager
      attach=".main-nav__container"
      max-width="200px"
  >
    <template #activator="{ on }">
      <v-btn text class="mx-2" :icon="$vuetify.breakpoint.smAndDown" v-on="on">
        <flag-icon
            :round="$vuetify.breakpoint.smAndDown"
            :flag="currentLocale.flag"
        ></flag-icon>
        <span
            v-show="$vuetify.breakpoint.mdAndUp && showLabel"
            :class="[$vuetify.rtl ? 'mr-1' : 'ml-1']"
            class="white--text"
        >{{ currentLocale.name }}</span
        >
        <v-icon v-if="showArrow" right>mdi-chevron-down</v-icon>
      </v-btn>
    </template>

    <v-list dense nav>
      <v-list-item
          v-for="locale in availableLocales"
          :key="locale.code"
          @click="setLocale(locale.code)"
      >
        <flag-icon
            :class="[$vuetify.rtl ? 'ml-1' : 'mr-1']"
            :flag="locale.flag"
        ></flag-icon>
        <v-list-item-title>{{ locale.name }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
// import {debounce} from 'lodash'
import FlagIcon from '@/components/common/FlagIcon'

/*
 |---------------------------------------------------------------------
 | Language Switcher Component
 |---------------------------------------------------------------------
 |
 | Locale menu to choose the language based on the locales present in
 | vue-i18n locales available array
 |
 */
export default {
  components: {
    FlagIcon,
  },
  props: {
    // Show dropdown arrow
    showArrow: {
      type: Boolean,
      default: false,
    },
    // Show the country label
    showLabel: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentLocale() {
      return this.$i18n.locales.find((i) => i.code === this.$i18n.locale)
    },
    availableLocales() {
      return this.$i18n.locales.filter((i) => i.code !== this.$i18n.locale)
    },
  },
  created() {
    this.$vuetify.lang.t = (key, ...params) => this.$i18n.t(key, params)
  },
  // mounted() {
  //   const interval = setInterval(debounce(() => {
  //     if (typeof window.doGTranslate === 'function') {
  //       console.log(this.currentLocale)
  //
  //       if (this.currentLocale.code === 'ro') {
  //           window.doGTranslate(`ro|ro`)
  //           return
  //         }
  //
  //         this.setLocale(this.currentLocale.code)
  //         clearInterval(interval)
  //       }
  //   }, 100), 50)
  // },
  methods: {
    setLocale(locale) {
      // eslint-disable-next-line no-unreachable
      this.$vuetify.lang.current = locale
      this.$router.push(this.switchLocalePath(locale))
      window.doGTranslate(`ro|${locale}`)
    },
  },
}
</script>
