import mutations from './mutations'

const state = () => ({
  citizenReportsCategories: [],
  manageModal: false,
})

export default {
  namespaced: true,
  state,
  mutations,
}
