<template>
  <v-card tile flat class="mb-2 overflow-hidden" v-bind="$attrs">
    <div
    >
      <blockquote :cite="data.facebook_url" class="fb-xfbml-parse-ignore">
        <a :href="data.facebook_url">Facebook</a>
      </blockquote>
    </div>
  </v-card>
</template>

<script>

export default {
  name: 'FacebookWidget',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  async mounted() {
    await this.$nextTick()
    if (window.FB) {
      // window.FB.XFBML.parse()
    }
  },
}
</script>
