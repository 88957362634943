<template>
  <div>
    <div
      class="main-nav__container transition-animations"
      :style="{
        backgroundColor: navigator.background_color,
      }"
    >
      <img
        class="px-2 py-1 d-inline-block pointer"
        height="70"
        width="auto"
        alt="Logo"
        :src="navigator.logo_url"
        @click="goTo(null)"
      />

      <template v-for="nav in navigator.items">
        <v-menu
          v-if="nav.children && nav.children.length > 1"
          :key="nav.id"
          open-on-hover
          bottom
          offset-y
          tile
          allow-overflow
          origin="top center"
          attach=".main-nav__container"
          transition="slide-y-transition"
          content-class="main-nav__menu-item overflow-auto"
          @input="hoveredItem = { children: [] }"
        >
          <template #activator="{ on }">
            <v-hover v-slot="{ hover }">
              <div
                class="main-nav__item transition-animations"
                :style="{
                  backgroundColor: hover
                    ? getHoverLinkColor(nav.color)
                    : 'initial',
                }"
                v-on="on"
                @click="goTo(nav)"
              >
                <span> {{ nav.name | uppercase }}</span>
              </div>
            </v-hover>
          </template>
          <v-card
            flat
            dark
            :color="getHoverLinkColor(nav.color)"
            tile
            width="100%"
            class="relative"
          >
            <div class="main-nav__icon__container">
              <v-icon color="white" class="main-nav__icon">
                {{
                  hoveredItem.icon
                    ? `mdi-${hoveredItem.icon}`
                    : `mdi-${nav.icon}`
                }}</v-icon
              >
            </div>
            <v-row no-gutters dense>
              <v-col cols="4">
                <ul class="main-nav__left-menu">
                  <template v-for="item in nav.children">
                    <li
                      v-if="item.name"
                      :key="`children-${item.id}`"
                      @mouseover="hoveredItem = item"
                      @click="goTo(item)"
                    >
                      {{ item.name }}
                      <v-icon v-if="item.url" small>mdi-open-in-new</v-icon>
                    </li>
                  </template>
                </ul>
              </v-col>
              <v-col
                v-if="
                  hoveredItem.id &&
                  hoveredItem.children &&
                  hoveredItem.children.length
                "
              >
                <ul class="main-nav__right-menu">
                  <template v-for="child in hoveredItem.children">
                    <li
                      v-if="child.name"
                      :key="`last-children-${child.id}`"
                      @click="goTo(child)"
                    >
                      {{ child.name }}
                      <v-icon v-if="child.url" small>mdi-open-in-new</v-icon>
                    </li>
                  </template>
                </ul>
              </v-col>
            </v-row>
          </v-card>
        </v-menu>
        <v-hover v-else v-slot="{ hover }" :key="nav.id">
          <div
            class="main-nav__item transition-animations"
            :style="{
              backgroundColor: hover ? getHoverLinkColor(nav.color) : 'initial',
            }"
            @click="goTo(nav)"
          >
            <span> {{ nav.name | uppercase }}</span>
          </div>
        </v-hover>
      </template>
      <ToolbarLanguage attach=".main-nav__container"></ToolbarLanguage>
      <ThemeChanger change-to-white></ThemeChanger>

      <v-menu offset-y left :close-on-content-click="false">
        <template #activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" class="mr-2" v-on="on">
            <v-icon color="white">mdi-magnify</v-icon>
          </v-btn>
        </template>
        <v-card class="pa-2" width="400px">
          <v-card-text>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('fields.search')"
              outlined
              :hint="$t('fields.search_hint')"
              @click:append="searchArticle"
              @keypress.enter="searchArticle"
            ></v-text-field>
            <v-btn
              text
              color="primary"
              large
              aria-label="Căutare site"
              block
              nuxt
              :to="localePath('/articles/search?filters=true')"
              >{{ $t('buttons.advanced_search') }}</v-btn
            >
          </v-card-text>
        </v-card>
      </v-menu>
      <edit-asset-button
        class="main-nav__item"
        :block="false"
        :link="`/navigator`"
      ></edit-asset-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import DeleteNodes from '@/mixins/recursive-delete'
import EditAssetButton from '~/components/common/EditAssetButton'
import ThemeChanger from '~/components/common/ThemeChanger'
import ToolbarLanguage from '~/components/toolbar/ToolbarLanguage'
import search from '~/mixins/search'

export default {
  name: 'FrontNavbar',
  components: {ThemeChanger, EditAssetButton, ToolbarLanguage },
  mixins: [DeleteNodes, search],
  props: {
    offsetTop: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      hoveredItem: {
        children: [],
      },
    }
  },
  fetch() {
    return this.createResource('navigators')
  },
  computed: {
    ...mapState('navigators', ['navigator']),
  },
  methods: {
    ...mapActions('resources', ['createResource']),
    getHoverLinkColor(color) {
      return color || 'var(--v-primary-base)'
    },
    goTo(item = null) {
      if (!item) {
        this.$router.push(this.localePath('/'))
        return
      }

      if (item.url) {
        window.open(item.url, item.link_in_new_tab ? '_blank' : '_self')
        return
      }

      if (item.model.includes('article')) {
        this.$router.push(this.localePath(`/articles/${item.slug}`))
        return
      }

      this.$router.push(
        this.localePath(`/categories/${item.model_id}/view/${item.slug}`)
      )
    },
  },
}
</script>
