export default {
  // global theme for the app
  isDarkTheme: false, // light | dark

  // side menu theme, use global theme or custom
  menuTheme: 'dark', // global | light | dark

  // toolbar theme, use global theme or custom
  toolbarTheme: 'dark', // global | light | dark

  // show toolbar detached from top
  isToolbarDetached: true,

  // wrap pages content with a max-width
  isContentBoxed: false,

  // application is right to left
  isRTL: false,

  // dark theme colors
  dark: {
    background: '#05090c',
    surface: '#111b27',
    primary: '#50C2BC',
    secondary: '#00897B',
    accent: '#0D47A1',
    error: '#C62828',
    info: '#006064',
    success: '#43A047',
    warning: '#EF6C00',
  },

  // light theme colors
  light: {
    background: '#ffffff',
    surface: '#f2f5f8',
    primary: '#50C2BC',
    secondary: '#00897B',
    accent: '#0D47A1',
    error: '#C62828',
    info: '#006064',
    success: '#43A047',
    warning: '#EF6C00',
  },
}
