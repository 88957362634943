import Vue from 'vue'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import VuetifyDraggableTreeview from 'vuetify-draggable-treeview'
import PortalVue from 'portal-vue'

Vue.use(CoolLightBox)
Vue.use(VuetifyDraggableTreeview)

Vue.use(PortalVue)
