import { camelCase, isObject } from 'lodash'

export default {
  async getData({ commit, rootState }, scope) {
    commit('loader/startLoading', null, { root: true })
    commit(`${camelCase(scope)}/setData`, [], { root: true })

    try {
      // eslint-disable-next-line prefer-const
      let { pagination, ...params } = rootState.query.queryParams[scope]

      params = Object.assign(params, pagination)
      const filledParams = {}

      Object.keys(params).forEach((paramKey) => {
        if (
          (params[paramKey] || params[paramKey] === 0) &&
          paramKey !== 'total'
        ) {
          filledParams[paramKey] = params[paramKey]
        }
      })

      const { data } = await this.$axios.get(`/api/${scope}`, {
        params: filledParams,
      })

      commit(`${camelCase(scope)}/setData`, data.items, { root: true })

      commit(
        'query/setTotalItems',
        {
          scope,
          total: data.pagination.total,
        },
        { root: true }
      )

      // eslint-disable-next-line no-empty
    } catch (e) {}

    commit('loader/stopLoading', null, { root: true })
  },

  async deleteResource({ commit, dispatch }, payload) {
    commit('loader/startLoading', null, { root: true })
    const id = payload.key ? payload.data[payload.key] : payload.data.id

    try {
      const res = await this.$warningAlert()

      if (res.isConfirmed) {
        const { data } = await this.$axios.post(`/api/${payload.scope}/` + id, {
          _method: 'DELETE',
        })

        await this.$successAlert(data.message)

        dispatch('getData', payload.scope)
      }
      // eslint-disable-next-line no-empty
    } catch (e) {
      if (process.client) {
        await this.$errorAlert(
          'Nu se poate șterge deoarece entitatea este' +
            ' folosită de alte entități'
        )
      }
    }

    commit('loader/stopLoading', null, { root: true })
  },

  async createResource({ commit }, scope) {
    commit('loader/startLoading', null, { root: true })

    try {
      const { data } = await this.$axios.get(`/api/${scope}/create`)

      commit(`${camelCase(scope)}/setShowData`, data.data, { root: true })
      // eslint-disable-next-line no-empty
    } catch (e) {}

    commit('loader/stopLoading', null, { root: true })
  },

  async createResourceWithParams({ commit }, payload) {
    commit('loader/startLoading', null, { root: true })

    try {
      const { data } = await this.$axios.get(`/api/${payload.scope}/create`, {
        params: payload.params,
      })

      commit(`${camelCase(payload.scope)}/setShowData`, data.data, {
        root: true,
      })
    } catch (e) {
      if (process.client) {
        await this.$errorAlert()
      }
    }

    commit('loader/stopLoading', null, { root: true })
  },

  async editResource({ commit }, payload) {
    commit('loader/startLoading', null, { root: true })
    const id = payload.key ? payload[payload.key] : payload.id

    try {
      const { data } = await this.$axios.get(`/api/${payload.scope}/${id}/edit`)

      commit(`${camelCase(payload.scope)}/setShowData`, data.data, {
        root: true,
      })
    } catch (e) {
      if (process.client) {
        await this.$errorAlert()
      }
    }

    commit('loader/stopLoading', null, { root: true })
  },

  async viewResource({ commit }, payload) {
    try {
      const { data } = await this.$axios.get(
        `/api/${payload.scope}/view/${payload.id}`
      )

      commit(`${camelCase(payload.scope)}/setResource`, data.data, {
        root: true,
      })
    } catch (e) {}
  },

  async storeResource({ commit, dispatch }, payload) {
    commit('loader/startLoading', null, { root: true })

    let payloadData = payload.data
    const headers = {}

    if (payload.isFormData) {
      headers['Content-Type'] = 'multipart/form-data'
      payloadData = dataToFormData(payloadData)
    }

    try {
      const { data } = await this.$axios.post(
        `/api/${payload.scope}`,
        payloadData,
        headers
      )

      await this.$successAlert(data.message)
      if (typeof payload.navigate === 'undefined') {
        this.$router.go(-1)
      } else if (payload.navigate) {
        await this.$router.replace(payload.navigate)
      }
      if (payload.refreshData) {
        dispatch('getData', payload.scope)
      }
    } catch (e) {
      if (e.response && e.response.data) {
        commit('errors/setErrors', e.response.data.errors, { root: true })
      }

      if (process.client) {
        await this.$errorAlert()
      }
    }

    commit('loader/stopLoading', null, { root: true })
  },

  async updateResource({ commit, dispatch }, payload) {
    commit('loader/startLoading', null, { root: true })
    const id = payload.key ? payload.data[payload.key] : payload.data.id
    let payloadData = payload.data
    const headers = {}
    payloadData._method = 'PATCH'

    if (payload.isFormData) {
      headers['Content-Type'] = 'multipart/form-data'
      payloadData = dataToFormData(payloadData)
    }

    try {
      const { data } = await this.$axios.post(
        `/api/${payload.scope}/${id}`,
        payloadData,
        headers
      )

      await this.$successAlert(data.message)
      if (typeof payload.navigate === 'undefined') {
        this.$router.go(-1)
      } else if (payload.navigate) {
        await this.$router.replace(payload.navigate)
      }
      if (payload.refreshData) {
        dispatch('getData', payload.scope)
      }
    } catch (e) {
      if (e.response && e.response.data) {
        commit('errors/setErrors', e.response.data.errors, { root: true })
      }
      await this.$errorAlert()
    }

    commit('loader/stopLoading', null, { root: true })
  },

  clearState({ commit }, payload) {
    commit(`${camelCase(payload.scope)}/setResource`, payload.val, {
      root: true,
    })
  },
}

function dataToFormData(data) {
  const formData = new FormData()
  Object.keys(data).forEach((key) => {
    if (
      isObject(data[key]) &&
      !(data[key] instanceof File) &&
      !(data[key] instanceof Blob)
    ) {
      formData.set(key, JSON.stringify(data[key]))
      return
    }

    if (data[key]) {
      if (!data[key]) {
        data[key] = ' '
      }

      formData.set(key, data[key])
    }
  })

  return formData
}
