export default function ({ $errorAlert, $auth, $axios, req, redirect }) {
  $axios.onRequest((config) => {
    if (!process.client && req && req.headers) {
      config.headers['X-TENANT'] = req.headers.host
    }
  })

  $axios.onError((error) => {
    const code = parseInt(error.response && error.response.status)
    if (code === 429) {
      $errorAlert(
        'Prea multe cereri către server! Vă rugam așteptați un minut.'
      )
    }
  })
}
