import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4d082f28 = () => interopDefault(import('../pages/articles/index.vue' /* webpackChunkName: "pages/articles/index" */))
const _7cc90157 = () => interopDefault(import('../pages/banner/index.vue' /* webpackChunkName: "pages/banner/index" */))
const _35195772 = () => interopDefault(import('../pages/categories/index.vue' /* webpackChunkName: "pages/categories/index" */))
const _23389236 = () => interopDefault(import('../pages/citizen-reports/index.vue' /* webpackChunkName: "pages/citizen-reports/index" */))
const _fc61f9aa = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _1479b25a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _380a312c = () => interopDefault(import('../pages/departments/index.vue' /* webpackChunkName: "pages/departments/index" */))
const _5e61b4a2 = () => interopDefault(import('../pages/navigator/index.vue' /* webpackChunkName: "pages/navigator/index" */))
const _233e3880 = () => interopDefault(import('../pages/petitions/index.vue' /* webpackChunkName: "pages/petitions/index" */))
const _6ddec988 = () => interopDefault(import('../pages/roles/index.vue' /* webpackChunkName: "pages/roles/index" */))
const _3d4f31da = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _32c726fa = () => interopDefault(import('../pages/widgets/index.vue' /* webpackChunkName: "pages/widgets/index" */))
const _7bb20096 = () => interopDefault(import('../pages/articles/create.vue' /* webpackChunkName: "pages/articles/create" */))
const _0086e17c = () => interopDefault(import('../pages/articles/search.vue' /* webpackChunkName: "pages/articles/search" */))
const _c5ab9962 = () => interopDefault(import('../pages/articles/types.vue' /* webpackChunkName: "pages/articles/types" */))
const _7817c146 = () => interopDefault(import('../pages/auth/forgot-password.vue' /* webpackChunkName: "pages/auth/forgot-password" */))
const _2a4f9728 = () => interopDefault(import('../pages/auth/reset-password.vue' /* webpackChunkName: "pages/auth/reset-password" */))
const _38e8901e = () => interopDefault(import('../pages/auth/signin.vue' /* webpackChunkName: "pages/auth/signin" */))
const _1ea90152 = () => interopDefault(import('../pages/categories/create.vue' /* webpackChunkName: "pages/categories/create" */))
const _ed58ca48 = () => interopDefault(import('../pages/citizen-reports/categories/index.vue' /* webpackChunkName: "pages/citizen-reports/categories/index" */))
const _2311f83c = () => interopDefault(import('../pages/citizen-reports/create/index.vue' /* webpackChunkName: "pages/citizen-reports/create/index" */))
const _2dccaf01 = () => interopDefault(import('../pages/petitions/create/index.vue' /* webpackChunkName: "pages/petitions/create/index" */))
const _75aeb236 = () => interopDefault(import('../pages/roles/create.vue' /* webpackChunkName: "pages/roles/create" */))
const _7168c60b = () => interopDefault(import('../pages/users/create.vue' /* webpackChunkName: "pages/users/create" */))
const _821776a8 = () => interopDefault(import('../pages/utility/help.vue' /* webpackChunkName: "pages/utility/help" */))
const _7a6ec578 = () => interopDefault(import('../pages/utility/maintenance.vue' /* webpackChunkName: "pages/utility/maintenance" */))
const _4dd20304 = () => interopDefault(import('../pages/widgets/create.vue' /* webpackChunkName: "pages/widgets/create" */))
const _4d67d2a6 = () => interopDefault(import('../pages/widgets/sort.vue' /* webpackChunkName: "pages/widgets/sort" */))
const _46772d42 = () => interopDefault(import('../pages/articles/edit/_slug.vue' /* webpackChunkName: "pages/articles/edit/_slug" */))
const _575e5d50 = () => interopDefault(import('../pages/categories/edit/_id.vue' /* webpackChunkName: "pages/categories/edit/_id" */))
const _af3f5722 = () => interopDefault(import('../pages/roles/edit/_id.vue' /* webpackChunkName: "pages/roles/edit/_id" */))
const _c43671f8 = () => interopDefault(import('../pages/users/edit/_id.vue' /* webpackChunkName: "pages/users/edit/_id" */))
const _0497b256 = () => interopDefault(import('../pages/users/profile/_id.vue' /* webpackChunkName: "pages/users/profile/_id" */))
const _04f2a9bd = () => interopDefault(import('../pages/widgets/edit/_id.vue' /* webpackChunkName: "pages/widgets/edit/_id" */))
const _4b518de0 = () => interopDefault(import('../pages/articles/_slug.vue' /* webpackChunkName: "pages/articles/_slug" */))
const _5229f1f0 = () => interopDefault(import('../pages/categories/_id/view/_slug.vue' /* webpackChunkName: "pages/categories/_id/view/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/articles",
    component: _4d082f28,
    name: "articles___ro"
  }, {
    path: "/banner",
    component: _7cc90157,
    name: "banner___ro"
  }, {
    path: "/categories",
    component: _35195772,
    name: "categories___ro"
  }, {
    path: "/citizen-reports",
    component: _23389236,
    name: "citizen-reports___ro"
  }, {
    path: "/contact",
    component: _fc61f9aa,
    name: "contact___ro"
  }, {
    path: "/de",
    component: _1479b25a,
    name: "index___de"
  }, {
    path: "/departments",
    component: _380a312c,
    name: "departments___ro"
  }, {
    path: "/en",
    component: _1479b25a,
    name: "index___en"
  }, {
    path: "/es",
    component: _1479b25a,
    name: "index___es"
  }, {
    path: "/fr",
    component: _1479b25a,
    name: "index___fr"
  }, {
    path: "/hu",
    component: _1479b25a,
    name: "index___hu"
  }, {
    path: "/it",
    component: _1479b25a,
    name: "index___it"
  }, {
    path: "/navigator",
    component: _5e61b4a2,
    name: "navigator___ro"
  }, {
    path: "/petitions",
    component: _233e3880,
    name: "petitions___ro"
  }, {
    path: "/roles",
    component: _6ddec988,
    name: "roles___ro"
  }, {
    path: "/uk",
    component: _1479b25a,
    name: "index___uk"
  }, {
    path: "/users",
    component: _3d4f31da,
    name: "users___ro"
  }, {
    path: "/widgets",
    component: _32c726fa,
    name: "widgets___ro"
  }, {
    path: "/articles/create",
    component: _7bb20096,
    name: "articles-create___ro"
  }, {
    path: "/articles/search",
    component: _0086e17c,
    name: "articles-search___ro"
  }, {
    path: "/articles/types",
    component: _c5ab9962,
    name: "articles-types___ro"
  }, {
    path: "/auth/forgot-password",
    component: _7817c146,
    name: "auth-forgot-password___ro"
  }, {
    path: "/auth/reset-password",
    component: _2a4f9728,
    name: "auth-reset-password___ro"
  }, {
    path: "/auth/signin",
    component: _38e8901e,
    name: "auth-signin___ro"
  }, {
    path: "/categories/create",
    component: _1ea90152,
    name: "categories-create___ro"
  }, {
    path: "/citizen-reports/categories",
    component: _ed58ca48,
    name: "citizen-reports-categories___ro"
  }, {
    path: "/citizen-reports/create",
    component: _2311f83c,
    name: "citizen-reports-create___ro"
  }, {
    path: "/de/articles",
    component: _4d082f28,
    name: "articles___de"
  }, {
    path: "/de/banner",
    component: _7cc90157,
    name: "banner___de"
  }, {
    path: "/de/categories",
    component: _35195772,
    name: "categories___de"
  }, {
    path: "/de/citizen-reports",
    component: _23389236,
    name: "citizen-reports___de"
  }, {
    path: "/de/contact",
    component: _fc61f9aa,
    name: "contact___de"
  }, {
    path: "/de/departments",
    component: _380a312c,
    name: "departments___de"
  }, {
    path: "/de/navigator",
    component: _5e61b4a2,
    name: "navigator___de"
  }, {
    path: "/de/petitions",
    component: _233e3880,
    name: "petitions___de"
  }, {
    path: "/de/roles",
    component: _6ddec988,
    name: "roles___de"
  }, {
    path: "/de/users",
    component: _3d4f31da,
    name: "users___de"
  }, {
    path: "/de/widgets",
    component: _32c726fa,
    name: "widgets___de"
  }, {
    path: "/en/articles",
    component: _4d082f28,
    name: "articles___en"
  }, {
    path: "/en/banner",
    component: _7cc90157,
    name: "banner___en"
  }, {
    path: "/en/categories",
    component: _35195772,
    name: "categories___en"
  }, {
    path: "/en/citizen-reports",
    component: _23389236,
    name: "citizen-reports___en"
  }, {
    path: "/en/contact",
    component: _fc61f9aa,
    name: "contact___en"
  }, {
    path: "/en/departments",
    component: _380a312c,
    name: "departments___en"
  }, {
    path: "/en/navigator",
    component: _5e61b4a2,
    name: "navigator___en"
  }, {
    path: "/en/petitions",
    component: _233e3880,
    name: "petitions___en"
  }, {
    path: "/en/roles",
    component: _6ddec988,
    name: "roles___en"
  }, {
    path: "/en/users",
    component: _3d4f31da,
    name: "users___en"
  }, {
    path: "/en/widgets",
    component: _32c726fa,
    name: "widgets___en"
  }, {
    path: "/es/articles",
    component: _4d082f28,
    name: "articles___es"
  }, {
    path: "/es/banner",
    component: _7cc90157,
    name: "banner___es"
  }, {
    path: "/es/categories",
    component: _35195772,
    name: "categories___es"
  }, {
    path: "/es/citizen-reports",
    component: _23389236,
    name: "citizen-reports___es"
  }, {
    path: "/es/contact",
    component: _fc61f9aa,
    name: "contact___es"
  }, {
    path: "/es/departments",
    component: _380a312c,
    name: "departments___es"
  }, {
    path: "/es/navigator",
    component: _5e61b4a2,
    name: "navigator___es"
  }, {
    path: "/es/petitions",
    component: _233e3880,
    name: "petitions___es"
  }, {
    path: "/es/roles",
    component: _6ddec988,
    name: "roles___es"
  }, {
    path: "/es/users",
    component: _3d4f31da,
    name: "users___es"
  }, {
    path: "/es/widgets",
    component: _32c726fa,
    name: "widgets___es"
  }, {
    path: "/fr/articles",
    component: _4d082f28,
    name: "articles___fr"
  }, {
    path: "/fr/banner",
    component: _7cc90157,
    name: "banner___fr"
  }, {
    path: "/fr/categories",
    component: _35195772,
    name: "categories___fr"
  }, {
    path: "/fr/citizen-reports",
    component: _23389236,
    name: "citizen-reports___fr"
  }, {
    path: "/fr/contact",
    component: _fc61f9aa,
    name: "contact___fr"
  }, {
    path: "/fr/departments",
    component: _380a312c,
    name: "departments___fr"
  }, {
    path: "/fr/navigator",
    component: _5e61b4a2,
    name: "navigator___fr"
  }, {
    path: "/fr/petitions",
    component: _233e3880,
    name: "petitions___fr"
  }, {
    path: "/fr/roles",
    component: _6ddec988,
    name: "roles___fr"
  }, {
    path: "/fr/users",
    component: _3d4f31da,
    name: "users___fr"
  }, {
    path: "/fr/widgets",
    component: _32c726fa,
    name: "widgets___fr"
  }, {
    path: "/hu/articles",
    component: _4d082f28,
    name: "articles___hu"
  }, {
    path: "/hu/banner",
    component: _7cc90157,
    name: "banner___hu"
  }, {
    path: "/hu/categories",
    component: _35195772,
    name: "categories___hu"
  }, {
    path: "/hu/citizen-reports",
    component: _23389236,
    name: "citizen-reports___hu"
  }, {
    path: "/hu/contact",
    component: _fc61f9aa,
    name: "contact___hu"
  }, {
    path: "/hu/departments",
    component: _380a312c,
    name: "departments___hu"
  }, {
    path: "/hu/navigator",
    component: _5e61b4a2,
    name: "navigator___hu"
  }, {
    path: "/hu/petitions",
    component: _233e3880,
    name: "petitions___hu"
  }, {
    path: "/hu/roles",
    component: _6ddec988,
    name: "roles___hu"
  }, {
    path: "/hu/users",
    component: _3d4f31da,
    name: "users___hu"
  }, {
    path: "/hu/widgets",
    component: _32c726fa,
    name: "widgets___hu"
  }, {
    path: "/it/articles",
    component: _4d082f28,
    name: "articles___it"
  }, {
    path: "/it/banner",
    component: _7cc90157,
    name: "banner___it"
  }, {
    path: "/it/categories",
    component: _35195772,
    name: "categories___it"
  }, {
    path: "/it/citizen-reports",
    component: _23389236,
    name: "citizen-reports___it"
  }, {
    path: "/it/contact",
    component: _fc61f9aa,
    name: "contact___it"
  }, {
    path: "/it/departments",
    component: _380a312c,
    name: "departments___it"
  }, {
    path: "/it/navigator",
    component: _5e61b4a2,
    name: "navigator___it"
  }, {
    path: "/it/petitions",
    component: _233e3880,
    name: "petitions___it"
  }, {
    path: "/it/roles",
    component: _6ddec988,
    name: "roles___it"
  }, {
    path: "/it/users",
    component: _3d4f31da,
    name: "users___it"
  }, {
    path: "/it/widgets",
    component: _32c726fa,
    name: "widgets___it"
  }, {
    path: "/petitions/create",
    component: _2dccaf01,
    name: "petitions-create___ro"
  }, {
    path: "/roles/create",
    component: _75aeb236,
    name: "roles-create___ro"
  }, {
    path: "/uk/articles",
    component: _4d082f28,
    name: "articles___uk"
  }, {
    path: "/uk/banner",
    component: _7cc90157,
    name: "banner___uk"
  }, {
    path: "/uk/categories",
    component: _35195772,
    name: "categories___uk"
  }, {
    path: "/uk/citizen-reports",
    component: _23389236,
    name: "citizen-reports___uk"
  }, {
    path: "/uk/contact",
    component: _fc61f9aa,
    name: "contact___uk"
  }, {
    path: "/uk/departments",
    component: _380a312c,
    name: "departments___uk"
  }, {
    path: "/uk/navigator",
    component: _5e61b4a2,
    name: "navigator___uk"
  }, {
    path: "/uk/petitions",
    component: _233e3880,
    name: "petitions___uk"
  }, {
    path: "/uk/roles",
    component: _6ddec988,
    name: "roles___uk"
  }, {
    path: "/uk/users",
    component: _3d4f31da,
    name: "users___uk"
  }, {
    path: "/uk/widgets",
    component: _32c726fa,
    name: "widgets___uk"
  }, {
    path: "/users/create",
    component: _7168c60b,
    name: "users-create___ro"
  }, {
    path: "/utility/help",
    component: _821776a8,
    name: "utility-help___ro"
  }, {
    path: "/utility/maintenance",
    component: _7a6ec578,
    name: "utility-maintenance___ro"
  }, {
    path: "/widgets/create",
    component: _4dd20304,
    name: "widgets-create___ro"
  }, {
    path: "/widgets/sort",
    component: _4d67d2a6,
    name: "widgets-sort___ro"
  }, {
    path: "/de/articles/create",
    component: _7bb20096,
    name: "articles-create___de"
  }, {
    path: "/de/articles/search",
    component: _0086e17c,
    name: "articles-search___de"
  }, {
    path: "/de/articles/types",
    component: _c5ab9962,
    name: "articles-types___de"
  }, {
    path: "/de/auth/forgot-password",
    component: _7817c146,
    name: "auth-forgot-password___de"
  }, {
    path: "/de/auth/reset-password",
    component: _2a4f9728,
    name: "auth-reset-password___de"
  }, {
    path: "/de/auth/signin",
    component: _38e8901e,
    name: "auth-signin___de"
  }, {
    path: "/de/categories/create",
    component: _1ea90152,
    name: "categories-create___de"
  }, {
    path: "/de/citizen-reports/categories",
    component: _ed58ca48,
    name: "citizen-reports-categories___de"
  }, {
    path: "/de/citizen-reports/create",
    component: _2311f83c,
    name: "citizen-reports-create___de"
  }, {
    path: "/de/petitions/create",
    component: _2dccaf01,
    name: "petitions-create___de"
  }, {
    path: "/de/roles/create",
    component: _75aeb236,
    name: "roles-create___de"
  }, {
    path: "/de/users/create",
    component: _7168c60b,
    name: "users-create___de"
  }, {
    path: "/de/utility/help",
    component: _821776a8,
    name: "utility-help___de"
  }, {
    path: "/de/utility/maintenance",
    component: _7a6ec578,
    name: "utility-maintenance___de"
  }, {
    path: "/de/widgets/create",
    component: _4dd20304,
    name: "widgets-create___de"
  }, {
    path: "/de/widgets/sort",
    component: _4d67d2a6,
    name: "widgets-sort___de"
  }, {
    path: "/en/articles/create",
    component: _7bb20096,
    name: "articles-create___en"
  }, {
    path: "/en/articles/search",
    component: _0086e17c,
    name: "articles-search___en"
  }, {
    path: "/en/articles/types",
    component: _c5ab9962,
    name: "articles-types___en"
  }, {
    path: "/en/auth/forgot-password",
    component: _7817c146,
    name: "auth-forgot-password___en"
  }, {
    path: "/en/auth/reset-password",
    component: _2a4f9728,
    name: "auth-reset-password___en"
  }, {
    path: "/en/auth/signin",
    component: _38e8901e,
    name: "auth-signin___en"
  }, {
    path: "/en/categories/create",
    component: _1ea90152,
    name: "categories-create___en"
  }, {
    path: "/en/citizen-reports/categories",
    component: _ed58ca48,
    name: "citizen-reports-categories___en"
  }, {
    path: "/en/citizen-reports/create",
    component: _2311f83c,
    name: "citizen-reports-create___en"
  }, {
    path: "/en/petitions/create",
    component: _2dccaf01,
    name: "petitions-create___en"
  }, {
    path: "/en/roles/create",
    component: _75aeb236,
    name: "roles-create___en"
  }, {
    path: "/en/users/create",
    component: _7168c60b,
    name: "users-create___en"
  }, {
    path: "/en/utility/help",
    component: _821776a8,
    name: "utility-help___en"
  }, {
    path: "/en/utility/maintenance",
    component: _7a6ec578,
    name: "utility-maintenance___en"
  }, {
    path: "/en/widgets/create",
    component: _4dd20304,
    name: "widgets-create___en"
  }, {
    path: "/en/widgets/sort",
    component: _4d67d2a6,
    name: "widgets-sort___en"
  }, {
    path: "/es/articles/create",
    component: _7bb20096,
    name: "articles-create___es"
  }, {
    path: "/es/articles/search",
    component: _0086e17c,
    name: "articles-search___es"
  }, {
    path: "/es/articles/types",
    component: _c5ab9962,
    name: "articles-types___es"
  }, {
    path: "/es/auth/forgot-password",
    component: _7817c146,
    name: "auth-forgot-password___es"
  }, {
    path: "/es/auth/reset-password",
    component: _2a4f9728,
    name: "auth-reset-password___es"
  }, {
    path: "/es/auth/signin",
    component: _38e8901e,
    name: "auth-signin___es"
  }, {
    path: "/es/categories/create",
    component: _1ea90152,
    name: "categories-create___es"
  }, {
    path: "/es/citizen-reports/categories",
    component: _ed58ca48,
    name: "citizen-reports-categories___es"
  }, {
    path: "/es/citizen-reports/create",
    component: _2311f83c,
    name: "citizen-reports-create___es"
  }, {
    path: "/es/petitions/create",
    component: _2dccaf01,
    name: "petitions-create___es"
  }, {
    path: "/es/roles/create",
    component: _75aeb236,
    name: "roles-create___es"
  }, {
    path: "/es/users/create",
    component: _7168c60b,
    name: "users-create___es"
  }, {
    path: "/es/utility/help",
    component: _821776a8,
    name: "utility-help___es"
  }, {
    path: "/es/utility/maintenance",
    component: _7a6ec578,
    name: "utility-maintenance___es"
  }, {
    path: "/es/widgets/create",
    component: _4dd20304,
    name: "widgets-create___es"
  }, {
    path: "/es/widgets/sort",
    component: _4d67d2a6,
    name: "widgets-sort___es"
  }, {
    path: "/fr/articles/create",
    component: _7bb20096,
    name: "articles-create___fr"
  }, {
    path: "/fr/articles/search",
    component: _0086e17c,
    name: "articles-search___fr"
  }, {
    path: "/fr/articles/types",
    component: _c5ab9962,
    name: "articles-types___fr"
  }, {
    path: "/fr/auth/forgot-password",
    component: _7817c146,
    name: "auth-forgot-password___fr"
  }, {
    path: "/fr/auth/reset-password",
    component: _2a4f9728,
    name: "auth-reset-password___fr"
  }, {
    path: "/fr/auth/signin",
    component: _38e8901e,
    name: "auth-signin___fr"
  }, {
    path: "/fr/categories/create",
    component: _1ea90152,
    name: "categories-create___fr"
  }, {
    path: "/fr/citizen-reports/categories",
    component: _ed58ca48,
    name: "citizen-reports-categories___fr"
  }, {
    path: "/fr/citizen-reports/create",
    component: _2311f83c,
    name: "citizen-reports-create___fr"
  }, {
    path: "/fr/petitions/create",
    component: _2dccaf01,
    name: "petitions-create___fr"
  }, {
    path: "/fr/roles/create",
    component: _75aeb236,
    name: "roles-create___fr"
  }, {
    path: "/fr/users/create",
    component: _7168c60b,
    name: "users-create___fr"
  }, {
    path: "/fr/utility/help",
    component: _821776a8,
    name: "utility-help___fr"
  }, {
    path: "/fr/utility/maintenance",
    component: _7a6ec578,
    name: "utility-maintenance___fr"
  }, {
    path: "/fr/widgets/create",
    component: _4dd20304,
    name: "widgets-create___fr"
  }, {
    path: "/fr/widgets/sort",
    component: _4d67d2a6,
    name: "widgets-sort___fr"
  }, {
    path: "/hu/articles/create",
    component: _7bb20096,
    name: "articles-create___hu"
  }, {
    path: "/hu/articles/search",
    component: _0086e17c,
    name: "articles-search___hu"
  }, {
    path: "/hu/articles/types",
    component: _c5ab9962,
    name: "articles-types___hu"
  }, {
    path: "/hu/auth/forgot-password",
    component: _7817c146,
    name: "auth-forgot-password___hu"
  }, {
    path: "/hu/auth/reset-password",
    component: _2a4f9728,
    name: "auth-reset-password___hu"
  }, {
    path: "/hu/auth/signin",
    component: _38e8901e,
    name: "auth-signin___hu"
  }, {
    path: "/hu/categories/create",
    component: _1ea90152,
    name: "categories-create___hu"
  }, {
    path: "/hu/citizen-reports/categories",
    component: _ed58ca48,
    name: "citizen-reports-categories___hu"
  }, {
    path: "/hu/citizen-reports/create",
    component: _2311f83c,
    name: "citizen-reports-create___hu"
  }, {
    path: "/hu/petitions/create",
    component: _2dccaf01,
    name: "petitions-create___hu"
  }, {
    path: "/hu/roles/create",
    component: _75aeb236,
    name: "roles-create___hu"
  }, {
    path: "/hu/users/create",
    component: _7168c60b,
    name: "users-create___hu"
  }, {
    path: "/hu/utility/help",
    component: _821776a8,
    name: "utility-help___hu"
  }, {
    path: "/hu/utility/maintenance",
    component: _7a6ec578,
    name: "utility-maintenance___hu"
  }, {
    path: "/hu/widgets/create",
    component: _4dd20304,
    name: "widgets-create___hu"
  }, {
    path: "/hu/widgets/sort",
    component: _4d67d2a6,
    name: "widgets-sort___hu"
  }, {
    path: "/it/articles/create",
    component: _7bb20096,
    name: "articles-create___it"
  }, {
    path: "/it/articles/search",
    component: _0086e17c,
    name: "articles-search___it"
  }, {
    path: "/it/articles/types",
    component: _c5ab9962,
    name: "articles-types___it"
  }, {
    path: "/it/auth/forgot-password",
    component: _7817c146,
    name: "auth-forgot-password___it"
  }, {
    path: "/it/auth/reset-password",
    component: _2a4f9728,
    name: "auth-reset-password___it"
  }, {
    path: "/it/auth/signin",
    component: _38e8901e,
    name: "auth-signin___it"
  }, {
    path: "/it/categories/create",
    component: _1ea90152,
    name: "categories-create___it"
  }, {
    path: "/it/citizen-reports/categories",
    component: _ed58ca48,
    name: "citizen-reports-categories___it"
  }, {
    path: "/it/citizen-reports/create",
    component: _2311f83c,
    name: "citizen-reports-create___it"
  }, {
    path: "/it/petitions/create",
    component: _2dccaf01,
    name: "petitions-create___it"
  }, {
    path: "/it/roles/create",
    component: _75aeb236,
    name: "roles-create___it"
  }, {
    path: "/it/users/create",
    component: _7168c60b,
    name: "users-create___it"
  }, {
    path: "/it/utility/help",
    component: _821776a8,
    name: "utility-help___it"
  }, {
    path: "/it/utility/maintenance",
    component: _7a6ec578,
    name: "utility-maintenance___it"
  }, {
    path: "/it/widgets/create",
    component: _4dd20304,
    name: "widgets-create___it"
  }, {
    path: "/it/widgets/sort",
    component: _4d67d2a6,
    name: "widgets-sort___it"
  }, {
    path: "/uk/articles/create",
    component: _7bb20096,
    name: "articles-create___uk"
  }, {
    path: "/uk/articles/search",
    component: _0086e17c,
    name: "articles-search___uk"
  }, {
    path: "/uk/articles/types",
    component: _c5ab9962,
    name: "articles-types___uk"
  }, {
    path: "/uk/auth/forgot-password",
    component: _7817c146,
    name: "auth-forgot-password___uk"
  }, {
    path: "/uk/auth/reset-password",
    component: _2a4f9728,
    name: "auth-reset-password___uk"
  }, {
    path: "/uk/auth/signin",
    component: _38e8901e,
    name: "auth-signin___uk"
  }, {
    path: "/uk/categories/create",
    component: _1ea90152,
    name: "categories-create___uk"
  }, {
    path: "/uk/citizen-reports/categories",
    component: _ed58ca48,
    name: "citizen-reports-categories___uk"
  }, {
    path: "/uk/citizen-reports/create",
    component: _2311f83c,
    name: "citizen-reports-create___uk"
  }, {
    path: "/uk/petitions/create",
    component: _2dccaf01,
    name: "petitions-create___uk"
  }, {
    path: "/uk/roles/create",
    component: _75aeb236,
    name: "roles-create___uk"
  }, {
    path: "/uk/users/create",
    component: _7168c60b,
    name: "users-create___uk"
  }, {
    path: "/uk/utility/help",
    component: _821776a8,
    name: "utility-help___uk"
  }, {
    path: "/uk/utility/maintenance",
    component: _7a6ec578,
    name: "utility-maintenance___uk"
  }, {
    path: "/uk/widgets/create",
    component: _4dd20304,
    name: "widgets-create___uk"
  }, {
    path: "/uk/widgets/sort",
    component: _4d67d2a6,
    name: "widgets-sort___uk"
  }, {
    path: "/de/articles/edit/:slug?",
    component: _46772d42,
    name: "articles-edit-slug___de"
  }, {
    path: "/de/categories/edit/:id?",
    component: _575e5d50,
    name: "categories-edit-id___de"
  }, {
    path: "/de/roles/edit/:id?",
    component: _af3f5722,
    name: "roles-edit-id___de"
  }, {
    path: "/de/users/edit/:id?",
    component: _c43671f8,
    name: "users-edit-id___de"
  }, {
    path: "/de/users/profile/:id?",
    component: _0497b256,
    name: "users-profile-id___de"
  }, {
    path: "/de/widgets/edit/:id?",
    component: _04f2a9bd,
    name: "widgets-edit-id___de"
  }, {
    path: "/en/articles/edit/:slug?",
    component: _46772d42,
    name: "articles-edit-slug___en"
  }, {
    path: "/en/categories/edit/:id?",
    component: _575e5d50,
    name: "categories-edit-id___en"
  }, {
    path: "/en/roles/edit/:id?",
    component: _af3f5722,
    name: "roles-edit-id___en"
  }, {
    path: "/en/users/edit/:id?",
    component: _c43671f8,
    name: "users-edit-id___en"
  }, {
    path: "/en/users/profile/:id?",
    component: _0497b256,
    name: "users-profile-id___en"
  }, {
    path: "/en/widgets/edit/:id?",
    component: _04f2a9bd,
    name: "widgets-edit-id___en"
  }, {
    path: "/es/articles/edit/:slug?",
    component: _46772d42,
    name: "articles-edit-slug___es"
  }, {
    path: "/es/categories/edit/:id?",
    component: _575e5d50,
    name: "categories-edit-id___es"
  }, {
    path: "/es/roles/edit/:id?",
    component: _af3f5722,
    name: "roles-edit-id___es"
  }, {
    path: "/es/users/edit/:id?",
    component: _c43671f8,
    name: "users-edit-id___es"
  }, {
    path: "/es/users/profile/:id?",
    component: _0497b256,
    name: "users-profile-id___es"
  }, {
    path: "/es/widgets/edit/:id?",
    component: _04f2a9bd,
    name: "widgets-edit-id___es"
  }, {
    path: "/fr/articles/edit/:slug?",
    component: _46772d42,
    name: "articles-edit-slug___fr"
  }, {
    path: "/fr/categories/edit/:id?",
    component: _575e5d50,
    name: "categories-edit-id___fr"
  }, {
    path: "/fr/roles/edit/:id?",
    component: _af3f5722,
    name: "roles-edit-id___fr"
  }, {
    path: "/fr/users/edit/:id?",
    component: _c43671f8,
    name: "users-edit-id___fr"
  }, {
    path: "/fr/users/profile/:id?",
    component: _0497b256,
    name: "users-profile-id___fr"
  }, {
    path: "/fr/widgets/edit/:id?",
    component: _04f2a9bd,
    name: "widgets-edit-id___fr"
  }, {
    path: "/hu/articles/edit/:slug?",
    component: _46772d42,
    name: "articles-edit-slug___hu"
  }, {
    path: "/hu/categories/edit/:id?",
    component: _575e5d50,
    name: "categories-edit-id___hu"
  }, {
    path: "/hu/roles/edit/:id?",
    component: _af3f5722,
    name: "roles-edit-id___hu"
  }, {
    path: "/hu/users/edit/:id?",
    component: _c43671f8,
    name: "users-edit-id___hu"
  }, {
    path: "/hu/users/profile/:id?",
    component: _0497b256,
    name: "users-profile-id___hu"
  }, {
    path: "/hu/widgets/edit/:id?",
    component: _04f2a9bd,
    name: "widgets-edit-id___hu"
  }, {
    path: "/it/articles/edit/:slug?",
    component: _46772d42,
    name: "articles-edit-slug___it"
  }, {
    path: "/it/categories/edit/:id?",
    component: _575e5d50,
    name: "categories-edit-id___it"
  }, {
    path: "/it/roles/edit/:id?",
    component: _af3f5722,
    name: "roles-edit-id___it"
  }, {
    path: "/it/users/edit/:id?",
    component: _c43671f8,
    name: "users-edit-id___it"
  }, {
    path: "/it/users/profile/:id?",
    component: _0497b256,
    name: "users-profile-id___it"
  }, {
    path: "/it/widgets/edit/:id?",
    component: _04f2a9bd,
    name: "widgets-edit-id___it"
  }, {
    path: "/uk/articles/edit/:slug?",
    component: _46772d42,
    name: "articles-edit-slug___uk"
  }, {
    path: "/uk/categories/edit/:id?",
    component: _575e5d50,
    name: "categories-edit-id___uk"
  }, {
    path: "/uk/roles/edit/:id?",
    component: _af3f5722,
    name: "roles-edit-id___uk"
  }, {
    path: "/uk/users/edit/:id?",
    component: _c43671f8,
    name: "users-edit-id___uk"
  }, {
    path: "/uk/users/profile/:id?",
    component: _0497b256,
    name: "users-profile-id___uk"
  }, {
    path: "/uk/widgets/edit/:id?",
    component: _04f2a9bd,
    name: "widgets-edit-id___uk"
  }, {
    path: "/articles/edit/:slug?",
    component: _46772d42,
    name: "articles-edit-slug___ro"
  }, {
    path: "/categories/edit/:id?",
    component: _575e5d50,
    name: "categories-edit-id___ro"
  }, {
    path: "/de/articles/:slug",
    component: _4b518de0,
    name: "articles-slug___de"
  }, {
    path: "/en/articles/:slug",
    component: _4b518de0,
    name: "articles-slug___en"
  }, {
    path: "/es/articles/:slug",
    component: _4b518de0,
    name: "articles-slug___es"
  }, {
    path: "/fr/articles/:slug",
    component: _4b518de0,
    name: "articles-slug___fr"
  }, {
    path: "/hu/articles/:slug",
    component: _4b518de0,
    name: "articles-slug___hu"
  }, {
    path: "/it/articles/:slug",
    component: _4b518de0,
    name: "articles-slug___it"
  }, {
    path: "/roles/edit/:id?",
    component: _af3f5722,
    name: "roles-edit-id___ro"
  }, {
    path: "/uk/articles/:slug",
    component: _4b518de0,
    name: "articles-slug___uk"
  }, {
    path: "/users/edit/:id?",
    component: _c43671f8,
    name: "users-edit-id___ro"
  }, {
    path: "/users/profile/:id?",
    component: _0497b256,
    name: "users-profile-id___ro"
  }, {
    path: "/widgets/edit/:id?",
    component: _04f2a9bd,
    name: "widgets-edit-id___ro"
  }, {
    path: "/de/categories/:id/view/:slug?",
    component: _5229f1f0,
    name: "categories-id-view-slug___de"
  }, {
    path: "/en/categories/:id/view/:slug?",
    component: _5229f1f0,
    name: "categories-id-view-slug___en"
  }, {
    path: "/es/categories/:id/view/:slug?",
    component: _5229f1f0,
    name: "categories-id-view-slug___es"
  }, {
    path: "/fr/categories/:id/view/:slug?",
    component: _5229f1f0,
    name: "categories-id-view-slug___fr"
  }, {
    path: "/hu/categories/:id/view/:slug?",
    component: _5229f1f0,
    name: "categories-id-view-slug___hu"
  }, {
    path: "/it/categories/:id/view/:slug?",
    component: _5229f1f0,
    name: "categories-id-view-slug___it"
  }, {
    path: "/uk/categories/:id/view/:slug?",
    component: _5229f1f0,
    name: "categories-id-view-slug___uk"
  }, {
    path: "/articles/:slug",
    component: _4b518de0,
    name: "articles-slug___ro"
  }, {
    path: "/categories/:id/view/:slug?",
    component: _5229f1f0,
    name: "categories-id-view-slug___ro"
  }, {
    path: "/",
    component: _1479b25a,
    name: "index___ro"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
