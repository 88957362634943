<template>
  <v-card
    tile
    flat
    class="mb-2 overflow-hidden"
    v-bind="$attrs"
    :color="getColor"
    :dark="!!theme"
  >
    <client-only>
      <froala-view v-model="textData"></froala-view>
    </client-only>
  </v-card>
</template>

<script>

export default {
  name: 'TextWidget',
  props: {
    text: {
      type: String,
      required: true,
      default: '',
    },
    opacity: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      textData: '',
    }
  },
  computed: {
    theme() {
        return this.$store.state.app.isDarkTheme
    },
    getColor() {
      if (this.opacity) return 'transparent'
      return ''
    },
  },
  mounted() {
    this.textData = this.text
  },
}
</script>
