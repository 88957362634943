import actions from './actions'
import mutations from './mutations'

const state = () => ({
  leftWidgets: [],
  footerWidgets: [],
  pageWidgets: [],
  rightWidgets: [],
  buttonWidgets: [],
  activeMenuItem: '',
})

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
