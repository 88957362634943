export default {
  async getData({ commit }) {
    try {
      const { data } = await this.$axios.$get('/api/widgets/home')
      commit('setData', data)

      // eslint-disable-next-line no-empty
    } catch (e) {}
  },
}
