import mutations from './mutations'

const state = () => ({
  errors: {},
  error: '',
})

export default {
  state,
  mutations,
}
