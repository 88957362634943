import mutations from './mutations'

const state = () => ({
  citizenReports: [],
})

export default {
  namespaced: true,
  state,
  mutations,
}
