export default {
  setData: (state, widgets) => {
    state.widgets = [...widgets]
  },

  setShowData: (state, data) => {
    state.widget = { ...data.widget }
  },

  setResource: (state, data) => {
    state.widget = { ...data }
  },
}
