import mutations from './mutations'

const state = () => ({
  categories: [],
  article: {},
})

export default {
  namespaced: true,
  state,
  mutations,
}
