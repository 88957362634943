export default {
  setData: (state, users) => {
    state.users = [...users]
  },

  setShowData: (state, data) => {
    state.departments = data.departments
    state.roles = data.roles
    state.user = { ...data.user }
  },
}
