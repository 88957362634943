const actions = {
  async nuxtServerInit({ commit }, { req }) {
    const res = await this.$axios.get('/api/tenant')
    commit('app/setTenantData', res.data)
  },
}

export default {
  actions,
}
