export default {
  data() {
    return {
      search: '',
    }
  },
  methods: {
    searchArticle() {
      this.$router.push(
        this.localePath('/articles/search?query=' + this.search)
      )

      this.search = ''
    },
  },
}
