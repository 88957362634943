<template>
  <v-hover v-slot="{ hover }">
    <v-card
      tile
      flat
      :elevation="hover ? 16 : 2"
      :class="{ 'on-hover': hover }"
      v-bind="$attrs"
      class="mb-2 overflow-hidden"
    >
      <a :href="data.url" target="_blank">
        <v-img alt="Imagine" :src="data.image"></v-img>
      </a>
    </v-card>
  </v-hover>
</template>

<script>

export default {
  name: 'ImageWidget',
  props: {
    data: {
      required: true,
      default: () => {},
      type: Object,
    },
  },
}
</script>
