export default {
  setData: (state, roles) => {
    state.roles = [...roles]
  },

  setShowData: (state, data) => {
    state.permissions = data.permissions
    state.role = { ...data.role }
  },
}
