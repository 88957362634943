<template>
  <div class="scroll-to-top-btn">
    <v-scale-transition>
      <v-btn
        v-if="offsetTop > 800"
        aria-label="Scroll sus la pagină"
        color="warning"
        role="button"
        fab
        small
        class="pa-0"
        @click="$vuetify.goTo(0)"
      >
        <v-icon large>mdi-chevron-up</v-icon>
      </v-btn>
    </v-scale-transition>
  </div>
</template>

<script>
export default {
  name: 'TheToTopButton',
  props: {
    offsetTop: {
      type: Number,
      required: true,
    },
  },
}
</script>

<style scoped>
.scroll-to-top-btn {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 8;
}
</style>
