import mutations from './mutations'
import actions from './actions'

const pagination = {
  itemsPerPage: 15,
  page: 1,
  total: 0,
}

const state = () => ({
  queryParams: {
    'citizen-reports-categories': {
      include: '',
      sort: 'name',
      pagination: { ...pagination },
      'filter[name]': '',
      'filter[icon]': '',
      'filter[email]': '',
    },
    'citizen-reports': {
      include: '',
      sort: '-created_at',
      pagination: { ...pagination },
      'filter[first_name]': '',
      'filter[last_name]': '',
      'filter[email]': '',
      'filter[phone]': '',
      'filter[status]': '',
      'filter[category]': '',
      'filter[created_at]': [],
      'filter[categories]': [],
    },
    petitions: {
      include: '',
      sort: '-created_at',
      pagination: { ...pagination },
      'filter[first_name]': '',
      'filter[last_name]': '',
      'filter[email]': '',
      'filter[created_at]': [],
    },
    widgets: {
      include: '',
      sort: '-order',
      pagination: { ...pagination },
      'filter[name]': '',
      'filter[type]': '',
      'filter[location]': '',
      'filter[footer]': '',
    },
    'home-articles': {
      include: '',
      sort: '',
      pagination: { ...pagination },
    },
    articles: {
      include: '',
      sort: '-created_at',
      pagination: { ...pagination },
      'filter[title]': '',
      'filter[description]': '',
      'filter[category_id]': '',
      'filter[article_type_id]': '',
      'filter[display_date]': [],
      'filter[show_on_home]': '',
      'filter[recommended]': '',
      'filter[published]': '',
    },
    users: {
      include: 'department',
      sort: 'name',
      pagination: { ...pagination },
      'filter[name]': '',
      'filter[email]': '',
      'filter[active]': '',
      'filter[with_role]': '',
      'filter[position]': '',
      'filter[department_id]': '',
    },
    departments: {
      include: '',
      sort: 'name',
      pagination: { ...pagination },
      'filter[name]': '',
    },
    roles: {
      include: 'permissions',
      sort: 'name',
      pagination: { ...pagination },
      'filter[name]': '',
      'filter[perm]': '',
    },
    categories: {
      include: '',
      sort: 'name',
      pagination: { ...pagination },
      'filter[name]': '',
      'filter[url]': '',
      'filter[show_on_home]': '',
      'filter[layout]': '',
      'filter[active]': '',
    },
    'article-types': {
      include: '',
      sort: 'name',
      pagination: { ...pagination },
      'filter[name]': '',
      'filter[blocks]': '',
    },
  },
  defaultParams: {
    'citizen-reports-categories': {
      include: '',
      sort: 'name',
      pagination: { ...pagination },
      'filter[name]': '',
      'filter[icon]': '',
      'filter[email]': '',
    },
    'citizen-reports': {
      include: '',
      sort: '-created_at',
      pagination: { ...pagination },
      'filter[first_name]': '',
      'filter[last_name]': '',
      'filter[email]': '',
      'filter[phone]': '',
      'filter[status]': '',
      'filter[category]': '',
      'filter[created_at]': [],
      'filter[categories]': [],
    },
    petitions: {
      include: '',
      sort: '-created_at',
      pagination: { ...pagination },
      'filter[first_name]': '',
      'filter[last_name]': '',
      'filter[email]': '',
      'filter[created_at]': [],
    },
    widgets: {
      include: '',
      sort: '-order',
      pagination: { ...pagination },
      'filter[name]': '',
      'filter[type]': '',
      'filter[location]': '',
      'filter[footer]': '',
    },
    'home-articles': {
      include: '',
      sort: '',
      pagination: { ...pagination },
    },
    articles: {
      include: '',
      sort: '-created_at',
      pagination: { ...pagination },
      'filter[title]': '',
      'filter[description]': '',
      'filter[category_id]': '',
      'filter[article_type_id]': '',
      'filter[display_date]': [],
      'filter[show_on_home]': '',
      'filter[recommended]': '',
      'filter[published]': '',
    },
    users: {
      include: 'department',
      sort: 'name',
      pagination: { ...pagination },
      'filter[name]': '',
      'filter[email]': '',
      'filter[active]': '',
      'filter[with_role]': '',
      'filter[position]': '',
      'filter[department_id]': '',
    },
    departments: {
      include: '',
      sort: '',
      pagination: { ...pagination },
      'filter[name]': '',
    },
    roles: {
      include: 'permissions',
      sort: 'name',
      pagination: { ...pagination },
      'filter[name]': '',
      'filter[perm]': '',
    },
    categories: {
      include: '',
      sort: 'name',
      pagination: { ...pagination },
      'filter[name]': '',
      'filter[url]': '',
      'filter[show_on_home]': '',
      'filter[layout]': '',
      'filter[active]': '',
    },
    'article-types': {
      include: '',
      sort: 'name',
      pagination: { ...pagination },
      'filter[name]': '',
      'filter[blocks]': '',
    },
  },
})

export default {
  state,
  mutations,
  actions,
}
