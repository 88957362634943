<template>
  <v-app>
    <div class="d-flex text-center flex-column flex-md-row flex-grow-1">
      <v-sheet
        class="
          layout-side
          mx-auto
          d-none d-md-flex
          flex-md-column
          justify-space-between
          px-2
        "
      >
        <div class="mt-3 mt-md-10 pa-2">
          <div class="display-3 font-weight-bold primary--text">
            {{ product.name }}
          </div>
          <div class="title my-2">
            {{ $t('login.motto') }}
          </div>
        </div>
        <img alt="Oraș" class="w-full mb-5" src="@/static/images/illustrations/town.svg" />
      </v-sheet>

      <v-sheet
        dark
        class="
          pa-2 pa-md-4
          flex-grow-1
          align-center
          justify-center
          d-flex
          flex-column
        "
      >
        <div class="mt-3 pa-2 d-block d-md-none">
          <div class="display-2 font-weight-bold primary--text">
            {{ product.name }}
          </div>
          <div class="title my-2">
            {{ $t('login.motto') }}
          </div>
        </div>
        <div class="layout-content ma-auto w-full">
          <nuxt />
        </div>
        <div class="overline mt-4">
          {{ product.name }} - {{ product.version }}
          <p class="mb-0">Un produs Hirama Tech</p>
        </div>
      </v-sheet>
    </div>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Auth',
  middleware: 'auth',
  auth: 'guest',
  computed: {
    ...mapState('app', ['product']),
  },
}
</script>

<style scoped>
.layout-side {
  width: 50%;
}

.layout-content {
  max-width: 480px;
}
</style>
