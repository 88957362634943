<template>
  <v-footer dark padless :style="footerBackground">
    <v-container>
      <v-row class="app-container mx-auto" no-gutters dense>
        <v-col cols="12">
          <v-row
            class="footer-widget-container"
            justify="center"
            no-gutters
            dense
          >
            <template v-for="widget in footerWidgets">
              <v-col
                v-if="widget.type === 'footer_menu'"
                :key="widget.id"
                cols="12"
                md="6"
                lg="3"
              >
                <component
                  :is="getFooterWidgetComponent(widget.type)"
                  :text="widget.text"
                  :data="widget"
                  opacity
                ></component>
              </v-col>
              <v-col
                v-if="widget.type === 'footer_text'"
                :key="widget.id"
                cols="12"
                class="text-center"
              >
                <component
                  :is="getFooterWidgetComponent(widget.type)"
                  :text="widget.text"
                  :data="widget"
                  opacity
                ></component>
              </v-col>
            </template>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        dense
        no-gutters
        class="app-content mx-auto"
        justify="center"
        align="center"
      >
        <v-col cols="12" md="6" class="text-center text-md-right">
          <img
            alt="Logo Hirama Tech"
            width="100px"
            class="d-inline-block"
            :src="require('@/assets/images/logo_svg.svg')"
          />
        </v-col>
        <v-col cols="12" md="6">
          <div class="text-center text-md-left">
            {{ $t('hirama') }}
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import { mapState } from 'vuex'
import MenuWidget from '~/components/widget/MenuWidget'
import TextWidget from '~/components/widget/TextWidget'

export default {
  name: 'AppFooter',
  components: {
    MenuWidget,
    TextWidget,
  },
  computed: {
    ...mapState('homeWidgets', ['footerWidgets']),
    ...mapState('navigators', ['navigator']),
    ...mapState('banners', ['banner']),
    footerBackground() {
      if (!this.banner.footer_image_url)
        return {
          backgroundColor: this.navigator.background_color,
        }
      return {
        backgroundImage: `url('${this.banner.footer_image_url}')`,
        backgroundColor: this.navigator.background_color,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundBlendMode: 'multiply',
      }
    },
  },
  methods: {
    getFooterWidgetComponent(type) {
      switch (type) {
        case 'footer_text':
          return 'TextWidget'
        case 'footer_menu':
          return 'MenuWidget'
      }
    },
  },
}
</script>
