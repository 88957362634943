<template>
  <v-card tile flat class="mb-2 overflow-hidden" v-bind="$attrs">
    <v-card-title class="pl-1">
      <span v-show="gallery.length" class="font-weight-bold">{{
          $t('fields.gallery')
                                                             }}</span>
    </v-card-title>
    <v-img
        max-width="280px"
        width="100%"
        class="pointer"
        :src="showcase"
        alt="Galerie"
        @click="index = 0">
    </v-img>
    <portal to="destination">
      <CoolLightBox
          :items="gallery"
          :index="index"
          full-screen
          effect="fade"
          :use-zoom-bar="true"
          @close="index = null"
      >
      </CoolLightBox>
    </portal>
  </v-card>
</template>
<script>

export default {
  name: 'GalleryWidget',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      index: null,
    }
  },
  computed: {
    gallery() {
      if (this.data.gallery && this.data.gallery.length) {
        return this.data.gallery
      }

      return []
    },
    showcase() {
      if (this.gallery.length) {
        return this.gallery[0]
      }

      return null
    },
  },
}
</script>
