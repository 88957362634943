<template>
  <div class="hidden-md-and-up main-nav__mobile_container">
    <v-app-bar dark>
      <v-app-bar-title>
        <v-img
          contain
          alt="Logo"
          :max-width="logoWidth"
          class="text-left"
          :src="navigator.logo_url"
          @click="goTo(null)"
        />
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <ToolbarLanguage attach=".main-nav__mobile_container"></ToolbarLanguage>
      <ThemeChanger></ThemeChanger>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      right
      width="100%"
      app
      dark
      :color="selectedItem ? selectedItem.color : navigator.background_color"
    >
      <template v-if="!selectedItem">
        <v-slide-x-transition leave-absolute mode="out-in">
          <div class="pa-2">
            <v-img
              class="d-inline-block pointer text-center"
              contain
              max-height="200px"
              :src="navigator.logo_url"
              @click="goTo(null)"
            />
            <v-text-field
              v-model="search"
              class="px-2 mt-2"
              append-icon="mdi-magnify"
              :label="$t('fields.search')"
              solo-inverted
              @click:append="searchArticle"
              @keypress.enter="searchArticle"
            ></v-text-field>
          </div>
        </v-slide-x-transition>
      </template>
      <template v-else>
        <v-slide-x-transition leave-absolute mode="out-in">
          <div>
            <div class="d-flex align-center justify-space-between pa-2">
              <h1 class="white--text">{{ selectedItem.name }}</h1>
              <v-icon @click="clearSelection">mdi-arrow-left</v-icon>
            </div>
            <v-text-field
              class="px-2 mt-2"
              prepend-inner-icon="mdi-magnify"
              :label="$t('fields.search')"
              solo-inverted
            ></v-text-field>
          </div>
        </v-slide-x-transition>
      </template>

      <v-slide-x-transition leave-absolute mode="out-in">
        <v-list v-if="!selectedItem">
          <template v-for="(nav, i) in navigator.items">
            <v-list-item
              v-if="nav.name"
              :key="i"
              :style="{ 'border-left': '6px solid ' + nav.color }"
              link
            >
              <v-list-item-content @click="goTo(nav, true)">
                <v-list-item-title>{{
                  nav.name | uppercase
                }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon v-if="nav.url">mdi-open-in-new</v-icon>
                <v-icon
                  v-if="nav.children && nav.children.length > 1"
                  @click="selectedItem = nav"
                  >mdi-arrow-right</v-icon
                >
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
      </v-slide-x-transition>
      <v-slide-x-transition leave-absolute mode="in-out">
        <v-list v-if="selectedItem && !selectedMenuItem">
          <template v-for="(nav, i) in selectedItem.children">
            <v-list-item v-if="nav.name" :key="i" link>
              <v-list-item-content @click="goTo(nav)">
                <v-list-item-title>{{
                  nav.name | uppercase
                }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon v-if="nav.url">mdi-open-in-new</v-icon>
                <v-icon
                  v-if="nav.children && nav.children.length > 1"
                  @click="selectedMenuItem = nav"
                  >mdi-arrow-right</v-icon
                >
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
      </v-slide-x-transition>
      <v-slide-x-transition leave-absolute mode="in-out">
        <v-list v-if="selectedMenuItem">
          <template v-for="(nav, i) in selectedMenuItem.children">
            <v-list-item v-if="nav.name" :key="i" link>
              <v-list-item-content @click="goTo(nav)">
                <v-list-item-title>{{
                  nav.name | uppercase
                }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon v-if="nav.url">mdi-open-in-new</v-icon>
                <v-icon
                  v-if="nav.children && nav.children.length > 1"
                  @click="selectedMenuItem = nav"
                  >mdi-arrow-right</v-icon
                >
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
      </v-slide-x-transition>
      <div v-if="selectedItem" class="main-nav__icon__container">
        <v-icon color="white" class="main-nav__icon">
          {{ selectedItem.icon ? `mdi-${selectedItem.icon}` : `` }}</v-icon
        >
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import DeleteNodes from '@/mixins/recursive-delete'
import ThemeChanger from '~/components/common/ThemeChanger'
import ToolbarLanguage from '~/components/toolbar/ToolbarLanguage'
import search from '~/mixins/search'

export default {
  name: 'FrontMobileNavbar',
  components: {ThemeChanger, ToolbarLanguage },
  mixins: [DeleteNodes, search],
  data() {
    return {
      drawer: false,
      selectedItem: null,
      selectedMenuItem: null,
    }
  },
  fetch() {
    return this.createResource('navigators')
  },
  computed: {
    ...mapState('navigators', ['navigator']),
    logoWidth() {
      return this.$vuetify.breakpoint.mdAndUp ? '100px' : '70px'
    }
  },
  methods: {
    ...mapActions('resources', ['createResource']),
    getHoverLinkColor(color) {
      return color || 'var(--v-primary-base)'
    },
    clearSelection() {
      this.selectedItem = null
      this.selectedMenuItem = null
    },
    goTo(item = null, top = false) {
      if (!item) {
        this.$router.push(this.localePath('/'))
        return
      }

      if (item.url) {
        window.open(item.url, item.link_in_new_tab ? '_blank' : '_self')
        return
      }

      if (item.children && item.children.length > 1) {
        if (top) {
          this.selectedItem = item
          return
        }

        this.selectedMenuItem = item
        return
      }

      if (item.model.includes('article')) {
        this.$router.push(this.localePath(`/articles/${item.slug}`))
        return
      }

      this.$router.push(
        this.localePath(`/categories/${item.model_id}/view/${item.slug}`)
      )
    },
  },
}
</script>
