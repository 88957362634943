export default {
  methods: {
    async goToWidgetLink(model, slug, url, newTab, id ) {
      if (url) {
        if (newTab) {
          window.open(url)
        } else {
          window.open(url, "_self")
        }
        return
      }
      const uri =
        model === 'category'
          ? `/categories/${id}/view/${slug}`
          : `/articles/${slug}`
      await this.$router.push(this.localePath(uri))
    },
  },
}
