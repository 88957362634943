const locale = 'ro'

export default {
  // current locale
  locale,

  // when translation is not available fallback to that locale
  fallbackLocale: 'ro',

  // availabled locales for user selection
  availableLocales: [
    {
      code: 'ro',
      flag: 'ro',
      name: 'Română',
      file: 'ro.js',
    },
    {
      code: 'en',
      flag: 'us',
      name: 'Engleză',
      file: 'en.js',
    },
    {
      code: 'uk',
      flag: 'ua',
      name: 'Ucrainiană',
      file: 'uk.js',
    },
    {
      code: 'hu',
      flag: 'hu',
      name: 'Maghiară',
      file: 'hu.js',
    },
    {
      code: 'de',
      flag: 'de',
      name: 'Germană',
      file: 'de.js',
    },
    {
      code: 'fr',
      flag: 'fr',
      name: 'Franceză',
      file: 'fr.js',
    },
    {
      code: 'it',
      flag: 'it',
      name: 'Italiană',
      file: 'it.js',
    },
    {
      code: 'es',
      flag: 'es',
      name: 'Spaniolă',
      file: 'es.js',
    },
  ],
}
