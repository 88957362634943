import theme from '@/configs/theme.js'
import ro from 'vuetify/es5/locale/ro'

export default {
  rtl: theme.isRTL,
  lang: {
    locales: { ro },
    current: 'ro',
  },
  theme: {
    dark: theme.isDarkTheme,
    options: {
      customProperties: true,
    },
    themes: {
      dark: theme.dark,
      light: theme.light,
    },
  },
}
